import http from '../axios'

/*export function obtenerProvincias() {
    return http.get(`/Ubicacion/Provincias`);
}

export function obtenerCantones(provincia) {
    return http.get(`/Ubicacion/Cantones?provincia=${provincia}`);
}

export function obtenerDistritos(provincia, canton) {
    return http.get(`/Ubicacion/Distritos?provincia=${provincia}&canton=${canton}`);
}

export function obtenerBarrios(provincia, canton, distrito) {
    return http.get(`/Ubicacion/Distritos?provincia=${provincia}&canton=${canton}&distrito=${distrito}`);
}*/

const Ubicacion = {
    obtenerProvincias() {
        return http.get(`/Ubicacion/Provincias`);
    },
    obtenerCantones(provincia) {
        return http.get(`/Ubicacion/Cantones?provincia=${provincia}`);
    },
    obtenerDistritos(provincia, canton) {
        return http.get(`/Ubicacion/Distritos?provincia=${provincia}&canton=${canton}`);
    },
    obtenerBarrios(provincia, canton, distrito) {
        return http.get(`/Ubicacion/Barrios?provincia=${provincia}&canton=${canton}&distrito=${distrito}`);
    }
}

export default Ubicacion;