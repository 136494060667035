import React, { useEffect, useState, useRef } from 'react'

import servicioPago from '../../services/pagos'
import { useToasts } from 'react-toast-notifications';

import Layout from '../../components/Layout'
import Container from "../../components/UI/ContainerCard/Container";
import Table from "../../components/UI/Table";
import Datetime from 'react-datetime';
import Spinner from '../../components/UI/Spinner/Spinner'

import moment from 'moment';
import 'moment/locale/es';
import 'moment-timezone';

const ListaPagos = () => {
    const { addToast } = useToasts();
    const numeroContrato = useRef();
    const nombre = useRef();
    //const fechaDesde = useRef(null);
    //const fechaHasta = useRef(null);
    const [fechaDesde, setFechaDesde] = useState(null)
    const [fechaHasta, setFechaHasta] = useState(null)
    const [estaCargando, setEstaCargando] = useState(true)
    const [totalDatos, setTotalDatos] = useState(0)
    const [pagina, setPagina] = useState(1)
    const [paginasTotales, setPaginasTotales] = useState(0)
    const maxResultados = 8;

    const columnasTabla1 = [
        { titulo: "Contrato", data: 'numeroContrato', tipo: 's' },
        { titulo: "Nombre", data: 'nombre', tipo: 's' },
        { titulo: "Tipo de Pago", data: 'tipoPago', tipo: 's' },
        { titulo: "Identificación", data: 'numeroIdentificacion', tipo: 's' },
        { titulo: "Descripción", data: 'descripcionPago', tipo: 's' },
        { titulo: "Fecha de Pago", data: 'fechaHoraPago', tipo: 's' },
        { titulo: "Total", data: 'totalPagado', tipo: 'c' },
        { titulo: "Acciones", data: 'idHistorialPago', tipo: 'r' }
    ]

    const [data, setData] = useState([])

    useEffect(() => {
        setPagina(1);
        obtenerPagos(1);
    }, [])

    const obtenerPagos = (pag) => {
        let fecDesde = moment.tz(moment(new Date()).add(-30, 'day'), 'America/Costa_Rica').format();
        let fecHasta = moment.tz('America/Costa_Rica').format();

        if (fechaDesde != null) {
            fecDesde = moment.tz(fechaDesde, 'America/Costa_Rica').format();
        }
        if (fechaHasta != null)
            fecHasta = moment.tz(fechaHasta, 'America/Costa_Rica').format();

        servicioPago.obtener(numeroContrato.current.value,
            nombre.current.value, fecDesde, fecHasta, maxResultados, pag).then((resp) => {
                if (resp.data) {
                    setPaginasTotales(Math.ceil(resp.data.cantidadRegistros / maxResultados));
                    setTotalDatos(resp.data.cantidadRegistros)
                    setData(resp.data.pagos)
                    setPagina(resp.data.pagina)
                }
                setEstaCargando(false)
            }).catch((error) => {
                let msg = "";
                if (error.error)
                    msg = error.error;
                addToast(`Ha ocurrido un error al obtener pagos. ${msg}`, { appearance: 'error', autoDismiss: true });
                setEstaCargando(false)
            });
    }

    const paginaSiguiente = () => {
        let pag = pagina + 1;
        setPagina(pag);
        setEstaCargando(true);
        obtenerPagos(pag);
    }
    const mostrarSiguiente = () => {
        return pagina < paginasTotales
    }
    const mostrarAnterior = () => {
        return pagina > 1
    }
    const paginaAnterior = () => {
        let pag = pagina - 1;
        setPagina(pag);
        setEstaCargando(true);
        obtenerPagos(pag);
    }

    const eliminar = (data) => {
        let seguro = window.confirm("¿Seguro que desea eliminar pago?");
        if (!seguro)
            return;

        setEstaCargando(true);
        servicioPago.eliminarPago(data).then(() => {
            addToast(`Pago eliminado correctamente.`, { appearance: 'success', autoDismiss: true });
            obtenerPagos(1);
        }).catch((error) => {
            let msg = "";
            if (error.error)
                msg = error.error;
            addToast(`Ha ocurrido un error al obtener pagos. ${msg}`, { appearance: 'error', autoDismiss: true });
            setEstaCargando(false)
        });
    }

    const submit = (e) => {
        e.preventDefault();
        setEstaCargando(true)
        obtenerPagos(1);
    }
    return (
        <>
            <Layout titulo="Lista de Pagos" subtitulo="Procesos &gt; Lista de Pagos" >
                <Container mostrartitulo="false">
                    <div>
                        <form className="mt-2 mx-2 mb-2" onSubmit={submit}>
                            <div className="grid md:grid-cols-6 gap-2">
                                <div className="mt-5 text-sm">
                                    <label htmlFor="numeroContrato" className="block text-black">N° de Contrato</label>
                                    <input type="text" id="numeroContrato" ref={numeroContrato} className={`rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full`} />
                                </div>
                                <div className="mt-5 text-sm">
                                    <label htmlFor="nombre" className="block text-black">Nombre</label>
                                    <input type="text" id="nombre" ref={nombre} className={`rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full`} />
                                </div>
                                <div className="mt-5 text-sm">
                                    <label htmlFor="fechaDesde" className="block text-black">Desde</label>
                                    <Datetime onChange={date => setFechaDesde(date)} id="fechaDesde" className="lista-pagos" initialValue={moment(new Date()).add(-30, 'day')} dateFormat="DD/MM/YYYY" timeFormat={false} locale="es" />
                                </div>
                                <div className="mt-5 text-sm">
                                    <label htmlFor="fechaHasta" className="block text-black">Hasta</label>
                                    <Datetime onChange={date => setFechaHasta(date)} id="fechaHasta" className="lista-pagos" initialValue={moment()} dateFormat="DD/MM/YYYY" timeFormat={false} locale="es" />
                                </div>
                                <div className="mt-5 text-sm">
                                    <button type="submit" className="btn transition bg-green-500 hover:bg-green-700 mt-8">Buscar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    {estaCargando && <Spinner />}
                    {!estaCargando &&
                        <Table columnas={columnasTabla1} filas={data} handleEliminar={eliminar} />}
                    {!estaCargando &&
                        <div className="mt-2 mx-2 mb-2">
                            <div className="grid md:grid-cols-6 gap-2 h-40" >
                                {mostrarAnterior() &&
                                    <div className="text-sm">
                                        <button onClick={paginaAnterior} className="btn transition bg-blue-500 hover:bg-blue-700 mt-8">
                                            <i class="fas fa-long-arrow-alt-left text-lg mr-2"></i>Página Anterior
                                    </button>
                                    </div>
                                }
                                {mostrarSiguiente() &&
                                    <div className="text-sm">
                                        < button onClick={paginaSiguiente} className="btn transition bg-blue-500 hover:bg-blue-700 mt-8">
                                            Página Siguiente<i class="fas fa-long-arrow-alt-right text-lg ml-2"></i>
                                        </button>
                                    </div>
                                }
                                <div className="mt-5 text-sm pt-5 col-span-4 text-lg font-bold">
                                    <span>{pagina} de {paginasTotales} páginas</span>
                                </div>
                            </div>
                        </div>
                    }
                </Container>
            </Layout>
        </>
    )
}

export default ListaPagos
