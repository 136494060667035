import React, { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import ticolinea from '../../images/ticolinea-logo1.jpg'
import { useHistory } from "react-router-dom"
import { useToasts } from 'react-toast-notifications';
import { obtenerPorEmail } from "../../services/usuarios";
import Input from '../../components/UI/Input/Input';
import Spinner from '../../components/UI/Spinner/Spinner';
import LoginSplash from './components/LoginSplash'
import { validarInput } from "../../helpers/validaciones";

const Login = () => {
    const { login } = useAuth();
    const history = useHistory();
    const { addToast } = useToasts();
    const initialState = {
        loginForm: {
            email: {
                elementType: 'input',
                label: 'Email',
                labelClassName: 'text-sm font-bold text-gray-700 tracking-wide',
                elementConfig: {
                    type: 'email',
                    placeholder: 'juan@ticolinea.com',
                    className: 'Input'
                },
                value: '',
                validation: {
                    required: true,
                    isEmail: true,
                    errorMsg: ""
                },
                valid: false,
                touched: false
            },
            password: {
                elementType: 'input',
                containerClassName: 'mt-8',
                label: 'Clave',
                labelClassName: 'text-sm font-bold text-gray-700 tracking-wide',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Ingrese su clave',
                    className: 'Input'
                },
                value: '',
                validation: {
                    required: true,
                    errorMsg: ""
                },
                valid: false,
                touched: false
            }
        },
        formIsValid: false,
        loading: false
    }
    const [form, setForm] = useState(initialState);

    const loginHandler = async (e) => {
        e.preventDefault();
        setForm({ ...form, loading: true });
        const formData = {};
        let error = "";
        for (let formElementIdentifier in form.loginForm) {
            formData[formElementIdentifier] = form.loginForm[formElementIdentifier].value;
        }
        try {
            login(formData.email, formData.password).then(async (usuario) => {
                sessionStorage.setItem('token', await usuario.user.getIdToken());
                obtenerPorEmail(formData.email).then((resp) => {
                    sessionStorage.setItem('usuario', resp.data.nombre);
                    history.push("/")
                }).catch((error) => {
                    setForm({ ...form, loading: false });

                    addToast("Ha ocurrido un error al verificar credenciales. " + error.error.message, { appearance: 'error', autoDismiss: true });
                    console.log(error)
                });
            }).catch((error) => {
                var errorCode = error.code;

                switch (errorCode) {
                    case "auth/invalid-email":
                        error = "Debe ingresar un correo electrónico válido"
                        break;
                    case "auth/wrong-password":
                        error = "Verifique sus credenciales e inténtelo de nuevo. [TL-001]"
                        break;
                    case "auth/user-not-found":
                        error = "Verifique sus credenciales e inténtelo de nuevo. [TL-002]"
                        break;
                    case "auth/too-many-requests":
                        error = "Ha ingresado muchas solicitudes de verificación. Inténtelo mas tarde."
                        break;
                    default:
                        error = "Ha ocurrido un error al verificar las credenciales"
                        break;
                }

                addToast(error, { appearance: 'error', autoDismiss: true });
                setForm(initialState);
            });
        } catch (ex) {
            addToast(error, { appearance: 'error', autoDismiss: true });
            setForm(initialState);
        }
    }

    const inputChangedHandler = (event, inputId) => {
        const loginFormActualizado = {
            ...form.loginForm
        };
        const elementoActualizado = {
            ...loginFormActualizado[inputId]
        };
        elementoActualizado.value = event.target.value;
        const validacion = validarInput(elementoActualizado.value, elementoActualizado.validation, inputId, elementoActualizado.label);
        elementoActualizado.valid = validacion.isValid;
        elementoActualizado.validation.errorMsg = validacion.isValid ? "" : validacion.msgError;
        elementoActualizado.touched = true;
        loginFormActualizado[inputId] = elementoActualizado;

        let formEsValido = true;
        for (let inputIdentifier in loginFormActualizado) {
            formEsValido = loginFormActualizado[inputIdentifier].valid && formEsValido;
        }
        setForm({ ...form, loginForm: loginFormActualizado, formIsValid: formEsValido });
    }

    const formElementsArray = [];
    for (let key in form.loginForm) {
        formElementsArray.push({
            id: key,
            config: form.loginForm[key]
        });
    }
    let formElement = (
        <form onSubmit={loginHandler}>
            {formElementsArray.map(formElement => (
                <Input
                    className="input"
                    containerClassName={formElement.config.containerClassName}
                    label={formElement.config.label}
                    labelClassName={formElement.config.labelClassName}
                    key={formElement.id}
                    elementType={formElement.config.elementType}
                    elementConfig={formElement.config.elementConfig}
                    value={formElement.config.value}
                    invalid={!formElement.config.valid}
                    shouldValidate={formElement.config.validation}
                    touched={formElement.config.touched}
                    changed={(event) => inputChangedHandler(event, formElement.id)} />
            ))}
            <div className="mt-10">
                <button disabled={!form.formIsValid || form.loading}
                    className="w-full bg-indigo-500 text-gray-100 rounded-full tracking-wide font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-indigo-600 shadow-lg py-4 disabled:opacity-50"
                    type="submit">
                    Iniciar Sesión
                    </button>
            </div>
        </form>
    );
    if (form.loading) {
        formElement = <Spinner />;
    }
    return (
        <div className="lg:flex container">
            <div className="lg:w-1/2 xl:max-w-screen-sm">
                <div className="py-12 bg-indigo-100 lg:bg-white flex justify-center lg:justify-start lg:px-12">
                    <div className="cursor-pointer flex items-center ">
                        <div>
                            <img className="w-24" src={ticolinea} alt="logo" />
                        </div>
                    </div>
                </div>
                <div className="mt-10 px-12 sm:px-24 md:px-48 lg:px-12 lg:mt-16 xl:px-24 xl:max-w-2xl">
                    <div className="mt-12">
                        {formElement}
                    </div>
                </div>
            </div>
            <LoginSplash />
        </div>
    )
}

export default Login
