import React, { useEffect, useState, useRef } from 'react'

import { useHistory } from 'react-router';

import ServicioFacturas from '../../../services/facturas'
import { useToasts } from 'react-toast-notifications';

import Table from "../../../components/UI/Table";
import Spinner from '../../../components/UI/Spinner/Spinner'

import moment from 'moment';
import 'moment/locale/es';
import 'moment-timezone';

const TablaFacturas = (props) => {
    const { addToast } = useToasts();
    const history = useHistory();
    const numeroContrato = useRef();
    const nombre = useRef();
    const [estaCargando, setEstaCargando] = useState(false)
    const [totalDatos, setTotalDatos] = useState(0)
    const [pagina, setPagina] = useState(1)
    const [paginasTotales, setPaginasTotales] = useState(0)
    const [EsEstadoInicial, setEsEstadoInicial] = useState(true)
    let maxResultados = 6;

    if (props.esModal) {
        maxResultados = 5;
    }

    const colFactura = [
        { titulo: "# Factura", data: 'idFactura', tipo: 's' },
        { titulo: "Total de Venta", data: 'totalVenta', tipo: 's' },
        { titulo: "Fecha Emisión", data: 'fechaEmision', tipo: 's' },
        { titulo: "Mes Facturado", data: 'mesFacturado', tipo: 's' },
        { titulo: "Vencimiento", data: 'fechaVencimiento', tipo: 's' },
    ]

    if (props.esModal)
        colFactura.push({ titulo: "Acciones", data: 'idFactura', tipo: 'seleccionar' })
    else
        colFactura.push({ titulo: "Acciones", data: 'idFactura', tipo: 'v' })

    const [data, setData] = useState([])

    useEffect(() => {
        setEstaCargando(true);
        setPagina(1);
        obtenerFacturas(1);
    }, [])

    const obtenerFacturas = (pag) => {
        ServicioFacturas.obtenerPorCliente(props.idCliente, maxResultados, pag).then((resp) => {
            setEstaCargando(false);
            if (resp.data) {
                console.log(resp.data)
                setPaginasTotales(Math.ceil(resp.data.cantidadRegistros / maxResultados));
                setTotalDatos(resp.data.cantidadRegistros)
                setData(resp.data.facturasEncabezado)
                setPagina(resp.data.pagina)
            }
            setEstaCargando(false)
        }).catch((error) => {
            let msg = "";
            if (error.error)
                msg = error.error;
            addToast(`Ha ocurrido un error al obtener clientes. ${msg}`, { appearance: 'error', autoDismiss: true });
            setEstaCargando(false)
        });
    }

    const paginaSiguiente = () => {
        let pag = pagina + 1;
        setPagina(pag);
        setEstaCargando(true);
        obtenerFacturas(pag);
    }
    const mostrarSiguiente = () => {
        return pagina < paginasTotales
    }
    const mostrarAnterior = () => {
        return pagina > 1
    }
    const paginaAnterior = () => {
        let pag = pagina - 1;
        setPagina(pag);
        setEstaCargando(true);
        obtenerFacturas(pag);
    }

    const verRegistro = (data) => {
        history.push(`/cliente/${data}`)
    }

    const submit = (e) => {
        e.preventDefault();
        setEsEstadoInicial(false)
        setEstaCargando(true)
        obtenerFacturas(1);
    }
    return (
        <>
            <div>
                <form className="mt-2 mx-2 mb-2" onSubmit={submit}>
                    <div className="grid md:grid-cols-6 gap-2">
                        <div className="mt-5 text-sm">
                            <button type="submit" className="btn transition bg-green-500 hover:bg-green-700 mt-8">Buscar</button>
                        </div>
                        {props.esModal &&
                            <div className="mt-14 text-sm col-span-4 text-center">
                                <span className="font-600 text-lg">Facturas pendientes de pago</span>
                            </div>}
                        {props.esModal &&
                            <div className="mt-5 text-sm">
                                <button type="button" onClick={props.onClose} className="btn transition bg-blue-500 hover:bg-blue-700 mt-8">X Cerrar</button>
                            </div>}

                        {!props.esModal &&
                            <div className="mt-5 text-sm">
                                <button type="button" onClick={() => history.push('/cliente')} className="btn transition bg-blue-500 hover:bg-blue-700 mt-8">Nuevo Cliente</button>
                            </div>
                        }
                    </div>
                </form>
            </div>
            {estaCargando && <Spinner />}
            {!estaCargando &&
                <Table columnas={colFactura} filas={data} verRegistro={verRegistro} seleccionar={props.seleccionar} />}
            {!estaCargando &&
                <div className="mt-2 mx-2 mb-2">
                    <div className="grid md:grid-cols-6 gap-2 h-40" >
                        {mostrarAnterior() &&
                            <div className="text-sm">
                                <button onClick={paginaAnterior} className="btn transition bg-blue-500 hover:bg-blue-700 mt-8">
                                    <i className="fas fa-long-arrow-alt-left text-lg mr-2"></i>Página Anterior
                                </button>
                            </div>
                        }
                        {mostrarSiguiente() &&
                            <div className="text-sm">
                                < button onClick={paginaSiguiente} className="btn transition bg-blue-500 hover:bg-blue-700 mt-8">
                                    Página Siguiente<i className="fas fa-long-arrow-alt-right text-lg ml-2"></i>
                                </button>
                            </div>
                        }
                        <div className="mt-5 text-sm pt-5 col-span-4 text-lg font-bold">
                            <span>{pagina} de {paginasTotales} páginas</span>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default TablaFacturas
