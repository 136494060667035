
import http from '../axios'

const ServicioFacturas = {
    obtener(numeroContrato, nombre, desde, hasta, maxDatos, pagina) {
        var query = '';
        if (numeroContrato)
            query += `numeroContrato=${numeroContrato}&`;
        if (nombre)
            query += `nombre=${nombre}&`;
        if (desde)
            query += `desde=${desde}&`;
        if (hasta)
            query += `hasta=${hasta}&`;
        if (maxDatos)
            query += `maxDatos=${maxDatos}&`;
        if (pagina)
            query += `pagina=${pagina}&`;

        return http.get(`/Facturas/Obtener?${query}`);
    },
    obtenerPorCliente(idCliente, maxDatos, pagina) {
        var query = '';

        query += `idCliente=${idCliente}&`;
        if (maxDatos)
            query += `maxDatos=${maxDatos}&`;
        if (pagina)
            query += `pagina=${pagina}&`;

        return http.get(`/Facturas/ObtenerPorCliente?${query}`);
    },
    obtenerCantidadClientesMorosos(){
        return http.get(`/Facturas/CantidadClientesMorosos`);
    },
    obtenerNoPagas(desde, hasta, maxDatos, pagina) {
        var query = '';
        if (desde)
            query += `desde=${desde}&`;
        if (hasta)
            query += `hasta=${hasta}&`;
        if (maxDatos)
            query += `maxDatos=${maxDatos}&`;
        if (pagina)
            query += `pagina=${pagina}&`;

        return http.get(`/Facturas/ObtenerNoPagas?${query}`);
    },
    eliminarFactura(id) {
        return http.delete(`/Facturas/Eliminar?id=${id}`)
    }
}


export default ServicioFacturas;