/* 
payload={
  nombre: Allan,
  apellido1: Salgado,
  apellido2: Solano,
  tipoIdentificacion: F,
  numeroIdentificacion: 114110914,
  nombreRepresentante: ,
  provincia: 1,
  canton: 1,
  distrito: 1,
  barrio: 1,
  direccion: direccion pruebas,
  telefono: 63499047,
  celular: 63499047,
  fax: null,
  lugarDeTrabajo: null,
  email: allan.salgado3@gmail.com,
  numeroContrato: 500,
  tipoFactura: D,
  emailFactura: allan.salgado3@gmail.com,
  diaCorte: 20,
  tipoCliente: 1
}
*/

import http from '../axios'

const Cliente = {
  crearCliente(payload) {
    return http.post('/Cliente/Crear', payload);
  },
  actualizarCliente(payload) {
    return http.post('/Cliente/Actualizar', payload);
  },
  obtenerClientes(numeroContrato, nombre, maxDatos, pagina) {
    var query = '';
    if (numeroContrato)
      query += `numeroContrato=${numeroContrato}&`;
    if (nombre)
      query += `nombre=${nombre}&`;
    if (maxDatos)
      query += `maxDatos=${maxDatos}&`;
    if (pagina)
      query += `pagina=${pagina}&`;

    return http.get(`/Cliente/Obtener?${query}`);
  },
  obtenerClientePorContrato(numeroContrato) {
    return http.get(`/Cliente/ObtenerPorContrato?numeroContrato=${numeroContrato}`);
  },
  obtenerClientePorId(id) {
    return http.get(`/Cliente/ObtenerPorId?id=${id}`);
  },
  obtenerFacturaTemporal(id, mesAFacturar, annoAFacturar) {
    console.log(mesAFacturar);
    return http.get(`/Cliente/ObtenerFacturaTemporal?clienteId=${id}&mesAfacturar=${mesAFacturar}&anno=${annoAFacturar}`);
  },
  facturar(id, mesAFacturar, annoAFacturar,fechaVencimiento) {
    return http.post(`/Cliente/Facturar?clienteId=${id}&mesAfacturar=${mesAFacturar}&anno=${annoAFacturar}&fechaVencimiento=${fechaVencimiento}`, null);
  },
  descargarFactura(id, mesAFacturar, annoAFacturar) {
    return http.get(`/Cliente/DescargarFactura?clienteId=${id}&mes=${mesAFacturar}&anno=${annoAFacturar}`);
  }
}


export default Cliente;