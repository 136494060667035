import React, { useEffect, useState } from 'react'

//Componentes
import { useToasts } from 'react-toast-notifications';
import Layout from '../../components/Layout'
import Container from "../../components/UI/ContainerCard/Container";
import CurrencyFormat from 'react-currency-format';
import Spinner from '../../components/UI/Spinner/Spinner'

//Servicios
import pagos from '../../services/pagos'

const PagoExterno = (props) => {
    const { addToast } = useToasts();
    const [estaCargando, setEstaCargando] = useState(true)
    const [data, setData] = useState({
        idReporte: 0,
        numeroContrato: 0,
        nombreCompleto: "",
        referencia: "",
        email: "",
        moneda: "",
        totalPagado: 0,
        fechaQueReporta: "",
        fechaCreacion: "",
        comentarios: "",
        urlImagen: "",
        estado: ""
    })
    const [moneda, setMoneda] = useState('₡');
    const [aprobado, setAprobado] = useState(false)
    const [aprobando, setAprobando] = useState(false)
    const [facturaACancelar, setFacturaACancelar] = useState("")


    useEffect(() => {
        var idReporte = props.match.params.id;
        pagos.obtenerReporteExterno(idReporte).then(resp => {
            setData(resp.data);
            if (resp.data.moneda === "USD") {
                setMoneda("$")
            }
            setEstaCargando(false)
        }).catch(err => {
            addToast("Ha ocurrido un error al reporte", { appearance: 'error', autoDismiss: true });
        })
    }, [])

    const aprobarPago = (e) => {
        e.preventDefault();
        setAprobando(true)
        pagos.aprobarPago({ idReporte: props.match.params.id, facturaACancelar: facturaACancelar }).then(() => {
            setAprobado(true)
            setAprobando(false)
            addToast("Aprobado correctamente", { appearance: 'success', autoDismiss: true });
        }).catch(err => {
            setAprobando(false)
            addToast("Ha ocurrido un error al aprobar comprobante", { appearance: 'error', autoDismiss: true });
        });
    }
    return (
        <>
            <Layout titulo="Información detallada del Reporte de Pago" subtitulo="Detalle &gt; Pago de Cliente" >
                <Container mostrartitulo="false">
                    {estaCargando && <Spinner />}
                    {!estaCargando && (
                        <div>
                            <div className="mt-2 mx-2">
                                {!aprobado && (
                                    <div className="grid md:grid-cols-6">
                                        <div className="text-sm col-end-7">
                                            <button disabled={aprobando ? "disabled" : null} onClick={aprobarPago} className="btn transition bg-green-500 hover:bg-green-700 mt-8">{aprobando ? "Aprobando..." : "Aprobar Reporte"}</button>
                                        </div>
                                    </div>
                                )}
                                <div className="grid md:grid-cols-6 gap-2">
                                    <div className="mt-5 text-sm">
                                        <label htmlFor="numeroContrato" className="block text-black">N° de Contrato</label>
                                        <input type="text" id="numeroContrato" readOnly className={`rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full`}
                                            value={data.numeroContrato} />
                                    </div>
                                    <div className="mt-5 text-sm col-span-3">
                                        <label htmlFor="facturaCancela" className="block text-black">Nombre</label>
                                        <input type="text" id="numeroContrato" readOnly className={`rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full`}
                                            value={data.nombreCompleto} />
                                    </div>
                                    <div className="mt-5 text-sm col-span-2 text-right pt-8 mr-2 border">
                                        <h2 className="text-3xl text-green-500 font-bold">
                                            <CurrencyFormat value={data.totalPagado} prefix={moneda} thousandSeparator={true} displayType={'text'} />
                                        </h2>
                                    </div>
                                </div>
                                <div className="grid md:grid-cols-6 gap-2 mt-2">
                                    <div className="mt-5 text-sm">
                                        <label htmlFor="nombre" className="block text-black">Referencia</label>
                                        <input type="text" id="nombre" readOnly className={`rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full`}
                                            value={data.referencia} />
                                    </div>
                                    <div className="mt-5 text-sm">
                                        <label htmlFor="nombre" className="block text-black">Fecha que Reporta</label>
                                        <input type="text" id="nombre" readOnly className={`rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full`}
                                            value={data.fechaQueReporta} />
                                    </div>
                                    <div className="mt-5 text-sm">
                                        <label htmlFor="nombre" className="block text-black">Email</label>
                                        <input type="text" id="nombre" readOnly className={`rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full`}
                                            value={data.email} />
                                    </div>
                                    <div className="mt-5 text-sm">
                                        <label htmlFor="nombre" className="block text-black">Factura A Cancelar</label>
                                        <input type="number" id="facturaACancelar" className={`rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full`}
                                            onChange={e => setFacturaACancelar(e.target.value)} />
                                    </div>
                                    <div className="mt-5 text-sm col-span-2">
                                        <label htmlFor="nombre" className="block text-black">Estado</label>
                                        <input type="text" id="nombre" readOnly className={`rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full`}
                                            value={!aprobado ? "Pendiente de Aprobar" : "Aprobado"} />
                                    </div>
                                </div>
                                <div className="grid md:grid-cols-6 gap-2 mt-2 mb-2">
                                    <div className="mt-5 text-sm col-span-6">
                                        <label htmlFor="nombre" className="block text-black">Comentarios</label>
                                        <input type="text" id="nombre" readOnly className={`rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full`}
                                            value={data.comentarios} />
                                    </div>
                                </div>
                                {data.urlImagen && (
                                    <div className="mx-auto mb-8 mt-4 text-center">
                                        <a target="blank" className="underline text-blue-600 pb-6" href={data.urlImagen}>Ampliar Comprobante</a>
                                        <br />
                                        <br />
                                        <img className="mx-auto" src={data.urlImagen} alt="comprobante" />
                                    </div>
                                )}
                                {!data.urlImagen && (
                                    <div className="mx-auto mb-8 mt-4">
                                        <h2>* No hay comprobante adjunto</h2>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    { }
                </Container>
            </Layout>
        </>
    )
}

export default PagoExterno
