import React from 'react'

import './XsSpinner.css'

const XsSpinner = () => {
    return (
        <>
            <div className="Loader-xs"></div>
        </>
    )
}

export default XsSpinner
