import React from 'react'

import Sidebar from './Sidebar'
import Navbar from './Navbar'

const Layout = (props) => {
    return (
        <>
            <Sidebar />
            <div className="relative md:ml-52 bg-blueGray-100">
                <Navbar titulo={props.titulo} subtitulo={props.subtitulo} />
                <div className="relative bg-blue-900 md:pt-20 pb-32 pt-12"></div>
                <div className="px-4 mx-auto w-full -m-24">
                    <div className="flex flex-wrap mt-4">
                        <div className="w-full mb-12 xl:mb-0 px-4">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Layout
