import React from 'react'

import CurrencyFormat from 'react-currency-format';

const Table = (props) => {
    const columnas = props.columnas
    const filas = props.filas

    function renderCell(tipo, data, key, idx) {
        if (tipo === 'c') {
            const prefix = data.charAt(0);
            var value = data.substring(1);
            return (
                <CurrencyFormat value={value} thousandSeparator={true} prefix={prefix} displayType={'text'} />
            )
        }
        else if (tipo === 'c0') {
            const prefix = data.charAt(0);
            var value0 = data.substring(1);
            return (
                <CurrencyFormat value={value0} prefix={prefix} displayType={'text'} />
            )
        }
        else if (tipo === 'bool') {
            if (data === 1)
                return (
                    <button title="Factura Pagada" style={{ width: 60 }} className="btn-table transition bg-green-500 hover:bg-green-700">SI</button>
                )
            else
                return (
                    <button title="Factura No Paga" style={{ width: 60 }} className="btn-table transition bg-red-500 hover:bg-red-700">NO</button>
                )
        }
        else if (tipo === 'r') {
            return (
                <button onClick={() => props.handleEliminar(data)} title="Eliminar" className="btn-table transition bg-red-500 hover:bg-red-700"><i className="far fa-trash-alt"></i></button>
            )
        }
        else if (tipo === 'r+') {
            return (
                <button onClick={() => props.handleEliminar(filas[idx])} title="Eliminar" className="btn-table transition bg-red-500 hover:bg-red-700"><i className="far fa-trash-alt"></i></button>
            )
        }
        else if (tipo === 'v') {
            return (
                <button onClick={() => props.verRegistro(data)} title="Ver Registro" className="btn-table transition bg-blue-500 hover:bg-blue-700 mr-1"><i className="fas fa-eye"></i></button>
            )
        }
        else if (tipo === 'seleccionar') {
            return (
                <button onClick={() => props.seleccionar(data)} className="btn-table transition bg-blue-500 hover:bg-blue-700 mr-1">Seleccionar</button>
            )
        }
        else if (tipo === 'cliente') {
            return (
                <div className="flex">
                    <button onClick={() => props.verRegistro(data)} className="btn-table transition bg-blue-500 hover:bg-blue-700 mr-1" title="Ver Registro"><i className="fas fa-eye"></i></button>
                    <button onClick={() => props.handleEliminar(data)} className="btn-table transition bg-green-500 hover:bg-green-700" title="Facturar"><i className="fas fa-file-invoice-dollar"></i></button>
                </div>

            )
        }
        else {
            return (
                <span>{data}</span>
            )
        }
    }

    function renderRow(y, r) {
        let row = [];
        let conf = [];
        columnas.forEach(columna => {
            row.push(y[columna.data]);
            conf.push(columna.tipo);
        });
        return (
            row.map((data, idx) => (
                <td key={r + idx} className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-1">
                    {renderCell(conf[idx], data, r + idx, idx)}
                </td>
            ))
        )
    }



    return (
        <>
            <table className="items-center w-full bg-transparent border-collapse">
                <thead>
                    <tr>
                        {columnas.map((columna, idx) => (
                            <th key={idx} className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                {columna.titulo}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {filas.map((fila, idx) => (
                        <tr key={idx}>
                            {renderRow(fila, idx)}
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}

export default Table
