import React from 'react';

import './Spinner.css'

const Spinner = () => {
    return (
        <>
            <div className="Loader">Cargando...</div>
        </>
    )
}
export default Spinner;