import React, { useEffect, useState } from 'react'

import background from "../../images/wifi.jpg";
import XsSpinner from '../../components/UI/Spinner/XsSpinner';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useToasts } from 'react-toast-notifications';


import servicioExterno from '../../services/externo'
import { validarInput } from "../../helpers/validaciones";

const Formulario = () => {

    const [form, setForm] = useState({
        nombreCompleto: {
            placeholder: 'Juanito Peréz Rosales',
            value: '',
            validation: {
                required: true,
                errorMsg: "",
                invalidClass: ''
            },
            valid: false,
            touched: false
        },
        email: {
            placeholder: 'juanito.perez@email.com',
            value: '',
            validation: {
                required: true,
                errorMsg: "",
                invalidClass: '',
                isEmail: true
            },
            valid: true,
            touched: false
        },
        telefono: {
            placeholder: '88888888',
            value: '',
            validation: {
                required: true,
                errorMsg: "",
                invalidClass: '',
                maxLength: 8,
                minLength: 8,
                isNumeric: true
            },
            valid: true,
            touched: false
        },
        fechaNacimiento: {
            placeholder: 'dd/MM/yyyy',
            value: '',
            validation: {
                required: true,
                errorMsg: "",
                invalidClass: ''
            },
            valid: false,
            touched: false
        }
    })
    const [esValido, setEsValido] = useState(false)
    const [estaCargando, setEstaCargando] = useState(false)
    const [fechaNacimiento, setFechaNacimiento] = useState();
    const [deshabilitarBoton, setDeshabilitarBoton] = useState(false);

    const { addToast } = useToasts();

    const cambiarValor = (id, valor) => {
        console.log(valor);
        const copiaForm = { ...form }
        copiaForm[id].value = valor

        const validacion = validarInput(copiaForm[id].value, copiaForm[id].validation, id);

        copiaForm[id].valid = validacion.isValid;
        copiaForm[id].validation.errorMsg = validacion.isValid ? "" : validacion.msgError;
        copiaForm[id].validation.invalidClass = validacion.isValid ? '' : 'input-invalid'

        let htmlFormEsValido = true;
        for (let inputIdentifier in copiaForm) {
            htmlFormEsValido = copiaForm[inputIdentifier].valid && htmlFormEsValido;
        }

        setEsValido(htmlFormEsValido);

        if (copiaForm[id].onlyNumbers) {
            const re = /^[0-9\b]+$/;
            if (valor === '' || re.test(valor)) {
                setForm({ ...copiaForm })
            }
        }
        else
            setForm({ ...copiaForm })
    }

    const cambiarPlaceholderMoneda = (id, valor) => {
        const copiaForm = { ...form }
        let placeholder = "";
        let prefijo = '';

        if (valor === "CRC") {
            placeholder = "₡ 0.00";
            prefijo = "₡";
        }
        else if (valor === "USD") {
            placeholder = "$ 0.00";
            prefijo = "$";
        }

        copiaForm['totalPagado'].placeholder = placeholder;
        copiaForm['totalPagado'].prefijo = prefijo;

        setForm({ ...copiaForm })
    }

    const handleChange = (e, id) => {
        if (id == "fechaNacimiento") {
            let fecha = e.format("DD-MM-YYYY");
            setFechaNacimiento(fecha);
            cambiarValor(id, e);
        }
        else {
            cambiarValor(id, e.target.value)
        }

        switch (id) {
            case 'moneda':
                cambiarPlaceholderMoneda(id, e.target.value)
                break;
            default:
                break;
        }
    }

    const submitPago = async (e) => {
        e.preventDefault();

        if (!esValido) {
            const copiaForm = { ...form };
            for (let inputIdentifier in form) {
                const validacion = validarInput(form[inputIdentifier].value, form[inputIdentifier].validation, inputIdentifier);
                copiaForm[inputIdentifier].valid = validacion.isValid;
                copiaForm[inputIdentifier].validation.errorMsg = validacion.isValid ? "" : validacion.msgError;
                copiaForm[inputIdentifier].validation.invalidClass = validacion.isValid ? '' : 'input-invalid'
            }
            setForm({ ...copiaForm });
            console.log(form);
            addToast('Debe verificar los campos requeridos para continuar.', { appearance: 'warning', autoDismiss: true });
            return;
        }

        window.grecaptcha.ready(_ => {
            window.grecaptcha
                .execute("6LcIIdQaAAAAAMsk3arny50T9bEdGJrpHLdT14NK", { action: "submit" })
                .then(token => {
                    setEstaCargando(true)

                    const payload = {
                        nombre: form.nombreCompleto.value,
                        email: form.email.value,
                        telefono: form.telefono.value,
                        fechaNacimiento: fechaNacimiento,
                        captchaToken: token
                    }

                    servicioExterno.crearFomulario(payload).then((resp) => {

                        if(!resp?.data.success){
                            addToast(`Ha ocurrido un error al crear registro. ${resp.data.error}`, { appearance: 'error', autoDismiss: true });
                            return;
                        }

                        addToast('Registro Enviado.', { appearance: 'success', autoDismiss: true });
                        setEstaCargando(false)
                        setForm({
                            nombreCompleto: {
                                placeholder: 'Juanito Peréz Rosales',
                                value: '',
                                validation: {
                                    required: true,
                                    errorMsg: "",
                                    invalidClass: ''
                                },
                                valid: false,
                                touched: false
                            },
                            email: {
                                placeholder: 'juanito.perez@email.com',
                                value: '',
                                validation: {
                                    required: true,
                                    errorMsg: "",
                                    invalidClass: '',
                                    isEmail: true
                                },
                                valid: true,
                                touched: false
                            },
                            telefono: {
                                placeholder: '88888888',
                                value: '',
                                validation: {
                                    required: true,
                                    errorMsg: "",
                                    invalidClass: '',
                                    maxLength: 8,
                                    minLength: 8,
                                    isNumeric: true
                                },
                                valid: true,
                                touched: false
                            },
                            fechaNacimiento: {
                                placeholder: 'dd/MM/yyyy',
                                value: '',
                                validation: {
                                    required: true,
                                    errorMsg: "",
                                    invalidClass: ''
                                },
                                valid: false,
                                touched: false
                            }
                        })
                        //setTimeout(function () { window.location.reload(); }, 2000);

                    }).catch((error) => {
                        let msg = "";
                        console.log(error.error)
                        if (error.error)
                            msg = error.error;
                        addToast(`Ha ocurrido un error al crear registro.`, { appearance: 'error', autoDismiss: true });
                        setEstaCargando(false)
                    })
                })
        });
    }

    /*useEffect(() => {
        // Add reCaptcha
        const script = document.createElement("script")
        script.src = "https://www.google.com/recaptcha/api.js?render=6LcIIdQaAAAAAMsk3arny50T9bEdGJrpHLdT14NK"
        document.body.appendChild(script);
        setDeshabilitarBoton(false);
    }, [])*/

    return (
        <div className="min-w-screen min-h-screen md:bg-gray-900 md:flex items-center justify-end px-5 py-5 bg-no-repeat bg-cover bg-left-top" style={{ backgroundImage: `url(${background})` }}>
            <div className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl lg:w-1/3 md:w-3/4 overflow-hidden">

                <form className="md:flex w-full" onSubmit={submitPago}>
                    <div className="w-full py-5 px-5 md:px-10">
                        <div className="flex flex-col justify-center items-center mb-5">
                            <h1 className="font-bold text-3xl text-gray-900">WiFi</h1>
                            <p>Completa el siguiente Formulario</p>
                        </div>
                        <div>
                            {estaCargando && (
                                <div className="my-32 text-center">
                                    <h2 className="text-lg">¡Gracias!</h2>
                                    <h2 className="text-2xl">Estamos procesando su registro...</h2>
                                </div>
                            )}
                            {!estaCargando && (
                                <div>
                                    <div className="flex -mx-3">
                                        <div className="w-full px-3 mb-2">
                                            <label htmlFor="nombreCompleto" className="text-xs font-semibold px-1">Nombre Completo</label>
                                            <div className="flex">
                                                <input id="nombreCompleto" type="text" className={`w-full pl-3 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500 ${form.nombreCompleto.validation.invalidClass}`}
                                                    placeholder={form.nombreCompleto.placeholder} value={form.nombreCompleto.value} onChange={(event) => handleChange(event, "nombreCompleto")} />
                                            </div>
                                            <div className='text-red-500 text-xs font-semibold'>
                                                {form.nombreCompleto.validation.errorMsg}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex -mx-3">
                                        <div className="w-full px-3 mb-2">
                                            <label htmlFor="email" className="text-xs font-semibold px-1">Correo Electrónico</label>
                                            <div className="flex">
                                                <input id="email" type="email" className={`w-full pl-3 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500 ${form.email.validation.invalidClass}`}
                                                    placeholder={form.email.placeholder} value={form.email.value} onChange={(event) => handleChange(event, "email")} />
                                            </div>
                                            <div className='text-red-500 text-xs font-semibold'>
                                                {form.email.validation.errorMsg}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="lg:flex -mx-3">
                                        <div className="w-full lg:w-3/4 px-3 mb-2">
                                            <label htmlFor="" className="text-xs font-semibold px-1">Teléfono - (Sin Guiones)</label>
                                            <div className="flex">
                                                <input id="telefono" maxLength={8} className={`w-full pl-3 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500  ${form.telefono.validation.invalidClass}`}
                                                    placeholder={form.telefono.placeholder} value={form.telefono.value} onChange={(event) => handleChange(event, "telefono")} />
                                            </div>
                                            <div className='text-red-500 text-xs font-semibold'>
                                                {form.telefono.validation.errorMsg}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex -mx-3">
                                        <div className="w-full px-3 mb-2">
                                            <label htmlFor="" className="text-xs font-semibold px-1">Fecha de Nacimiento</label>
                                            <div className="flex">
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <DatePicker closeOnSelect={true} className='bg-white' value={form.fechaNacimiento.value} disableFuture onChange={(newValue) => handleChange(newValue, "fechaNacimiento")} />
                                                </LocalizationProvider>
                                            </div>
                                            <div className='text-red-500 text-xs font-semibold'>
                                                {form.fechaNacimiento.validation.errorMsg}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="flex -mx-3">
                                <div className="w-full px-3 mb-2">
                                    <div
                                        className="g-recaptcha"
                                        data-sitekey="_reCAPTCHA_site_key_"
                                        data-size="invisible"></div>
                                </div>

                            </div>
                            {estaCargando && (
                                <div className="grid md:grid-cols-1 gap-2">
                                    <XsSpinner />
                                </div>
                            )}
                            {!estaCargando && (
                                <div className="flex -mx-3">
                                    <div className="w-full px-3 mb-5">
                                        <button disabled={deshabilitarBoton} className={`block w-full max-w-xs mx-auto text-white rounded-lg px-3 py-3 font-semibold ${deshabilitarBoton ? "bg-gray-500" : " bg-indigo-500 hover:bg-indigo-700 focus:bg-indigo-700"}`}>CONECTAR</button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Formulario;