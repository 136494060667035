import './App.css';
import { AuthProvider } from './contexts/AuthContext';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute';
import login from './pages/Login';
import dashboard from './pages/Dashboard';
import clientes from "./pages/Clientes"
import listaClientes from "./pages/ListaClientes"
import pago from "./pages/Pago"
import listaPagos from './pages/ListaPagos'
import listaPagosExternos from './pages/ListaPagosExternos'
import reportePagoExterno from './pages/ReportePagoExterno'
import Formulario from './pages/Formulario';
import pagoExterno from './pages/PagoExterno'
import servicios from './pages/Servicios'
import listaFacturas from './pages/Facturas'
import { ToastProvider } from 'react-toast-notifications';


function App() {
  return (
    <ToastProvider>
      <AuthProvider>
        <Router>
          <div>
            <Route exact path="/login" component={login} />
            <PrivateRoute exact path="/" component={dashboard} />
            <PrivateRoute exact path="/clientes" component={listaClientes} />
            <PrivateRoute exact path="/cliente" component={clientes} />
            <PrivateRoute exact path="/cliente/:id" component={clientes} />
            <PrivateRoute exact path="/pago" component={pago} />
            <PrivateRoute exact path="/pagos" component={listaPagos} />
            <PrivateRoute exact path="/pagosExternos" component={listaPagosExternos} />
            <PrivateRoute exact path="/pagosExterno/:id" component={pagoExterno} />
            <PrivateRoute exact path="/servicios" component={servicios} />
            <PrivateRoute exact path="/listaFacturas" component={listaFacturas} />
            <Route exact path="/reportarmipago" component={reportePagoExterno} />
            <Route exact path="/formulariowifi" component={Formulario} />
          </div>
        </Router>
      </AuthProvider>
    </ToastProvider>
  );
}

export default App;
