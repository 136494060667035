import React, { useEffect, useState } from 'react'
import UserDropdown from "./UserDropdown.js";
import Chat from '../components/Chat'

function Navbar(props) {
    const [usuario, setUsuario] = useState("")

    useEffect(() => {
        setUsuario(sessionStorage.getItem('usuario'))
    }, [])
    return (
        <>
            <Chat />
            <nav className="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4">
                <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
                    <div className="flex flex-col mt-4 text-white">
                        <a className="text-white text-sm uppercase hidden lg:inline-block font-semibold" href="#dashboard" onClick={e => e.preventDefault()}>
                            {props.titulo}
                        </a>
                        <span className="font-mono text-xs">
                            {props.subtitulo}
                        </span>
                    </div>

                    <span className="md:flex hidden text-white flex-row flex-wrap items-center lg:ml-auto mr-3 ">
                        <h2>Hola, {usuario}</h2>
                    </span>
                    <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
                        <UserDropdown />
                    </ul>
                </div>
            </nav>
        </>
    )
}

export default Navbar
