import React, { useEffect } from 'react'

const Chat = () => {
    useEffect(() => {
        var ldk = document.createElement('script');
        ldk.type = 'text/javascript';
        ldk.async = true;
        ldk.src = 'https://s.cliengo.com/weboptimizer/6100271785c459002a5edc6f/6100271985c459002a5edc77.js?platform=dashboard';
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(ldk, s);
    }, [])
    return (
        <>
        </>
    )
}

export default Chat
