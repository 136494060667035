import React from 'react'

import imgLoginSplash from '../../../images/bienvenido-ticolinea.png'

const LoginSplash = () => {
    return (
        <div className="hidden lg:flex items-center justify-center bg-indigo-100 flex-1 h-screen">
            <div className="max-w-s transform duration-200 hover:scale-110 cursor-pointer">
                <img className="w-2/3 mx-auto" src={imgLoginSplash} alt="login-splash" />
            </div>
        </div>
    )
}

export default LoginSplash
