import React from "react";
import { createPopper } from "@popperjs/core";
import { useAuth } from '../contexts/AuthContext'
import { useHistory } from "react-router-dom"

const UserDropdown = () => {
    const history = useHistory();
    const { signOut } = useAuth()
    const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
    const btnDropdownRef = React.createRef();
    const popoverDropdownRef = React.createRef();
    const openDropdownPopover = () => {
        createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
            placement: "bottom-end"
        });
        setDropdownPopoverShow(true);
    };
    const logOff = (e) => {
        e.preventDefault();
        signOut();
        history.push("/login");
        window.location.reload();
    }

    const closeDropdownPopover = () => {
        setDropdownPopoverShow(false);
    };
    return (
        <>
            <a className="text-blueGray-500 block" href="#dashboard" ref={btnDropdownRef} onClick={e => { e.preventDefault(); dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover(); }}>
                <div className="items-center flex">
                    <span className="w-12 h-12 text-sm text-white bg-blue-400 inline-flex items-center justify-center rounded-full">
                        <i className="fas fa-user"></i>
                    </span>
                </div>
            </a>
            <div ref={popoverDropdownRef} className={(dropdownPopoverShow ? "block " : "hidden ") + "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1"} style={{ minWidth: "12rem" }}>
                <a href="#/" className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700" onClick={logOff}>
                    Cerrar Sesión
                </a>
            </div>
        </>
    );
};

export default UserDropdown;