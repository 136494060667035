

export const validarInput = (valor, reglas, campo) => {
    let isValid = true;
    let msgError = "";

    if (!reglas) {
        return { isValid, msgError };
    }

    if (reglas.required && valor === null) {
        isValid = false;
        msgError = `* Campo ${campo} no puede estar en blanco`;
        return { isValid, msgError };
    }
    if (reglas.required) {
        isValid = valor !== '' && isValid;
        msgError = `* Campo ${campo} no puede estar en blanco`;
    }

    if (reglas.minLength) {
        isValid = valor.length >= reglas.minLength && isValid
        msgError = `* ${campo} no es válido.`;

        return { isValid, msgError };
    }

    if (reglas.maxLength) {
        isValid = valor.length <= reglas.maxLength && isValid
        msgError = `* ${campo} no es válido.`;

        return { isValid, msgError };
    }

    if (reglas.isEmail) {
        if (valor !== '') {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(valor) && isValid
            msgError = `* ${campo} no es válido`;
        }
    }

    if (reglas.isNumeric) {
        const pattern = /^\d+$/;
        isValid = pattern.test(valor) && isValid
        msgError = `* ${campo} debe contener solo números`;
    }
    if (reglas.isCurrency) {
        const pattern = /^[0-9,.]+$/;
        isValid = pattern.test(valor) && isValid
        msgError = `* ${campo} debe contener solo números`;
    }

    return { isValid, msgError };
}
