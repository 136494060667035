import React, { useContext, useState, useEffect } from 'react'
import { auth } from '../firebase'

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)

    function signOut() {
        auth.signOut();
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('usuario');
        setCurrentUser([]);
    }

    function login(email, password) {
        return auth.signInWithEmailAndPassword(email, password);
    }

    useEffect(() => {
        auth.onAuthStateChanged(async user => {
            if (user) {
                setCurrentUser(user)
                var token = await user.getIdToken()
                sessionStorage.setItem('token', token);
            }
            setLoading(false)
        })

        auth.onIdTokenChanged(async user => {
            if (user) {
                sessionStorage.setItem('token', await user.getIdToken());
            }
        })

        return () => console.log('unmounting...');
    }, [])

    const value = {
        currentUser,
        login,
        signOut
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
