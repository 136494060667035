import React from 'react'
import Header from "./Header";

const Container = (props) => {
    return (
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
            {(props.mostrartitulo === "true") &&
                <Header Titulo={props.Titulo} boton={props.boton} accionBoton={props.accionBoton} />
            }
            <div className="block w-full overflow-x-auto">
                {props.children}
            </div>
        </div>
    )
}

export default Container
