import React, { useEffect, useState } from 'react'
import { uploadFile } from 'react-s3';

import background from "../../images/fondo-reporte-pago.jpg";
import logo from '../../images/tl-transparente.png'
import Datetime from 'react-datetime';
import CurrencyhtmlFormat from 'react-currency-format';
import XsSpinner from '../../components/UI/Spinner/XsSpinner';

import { useToasts } from 'react-toast-notifications';


import servicioExterno from '../../services/externo'
import { validarInput } from "../../helpers/validaciones";

import moment from 'moment';
import 'moment/locale/es';
import 'moment-timezone';

const ReportePagoExterno = () => {
    const config = {
        bucketName: process.env.REACT_APP_S3_BUCKET,
        region: process.env.REACT_APP_REGION,
        accessKeyId: process.env.REACT_APP_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    }

    const [form, setForm] = useState({
        numeroContrato: {
            placeholder: '0000000',
            value: '',
            onlyNumbers: true,
            validation: {
                required: true,
                isNumeric: true,
                errorMsg: "",
            },
            valid: false,
            touched: false
        },
        nombreCompleto: {
            placeholder: 'Juanito Peréz Rosales',
            value: '',
            validation: {
                required: true,
                errorMsg: "",
                invalidClass: ''
            },
            valid: false,
            touched: false
        },
        email: {
            placeholder: 'juanito.perez@email.com',
            value: '',
            validation: {
                required: false,
                errorMsg: "",
                invalidClass: ''
            },
            valid: true,
            touched: false
        },
        comentario: {
            placeholder: 'Comentarios',
            value: '',
            validation: {
                required: false,
                errorMsg: "",
                invalidClass: ''
            },
            valid: true,
            touched: false
        },
        moneda: {
            placeholder: '',
            value: 'CRC',
            validation: {
                required: false,
                errorMsg: "",
                invalidClass: ''
            },
            valid: true,
            touched: false
        },
        totalPagado: {
            placeholder: '₡ 0.00',
            prefijo: '₡',
            value: '',
            validation: {
                required: true,
                errorMsg: "",
                isCurrency: true,
                invalidClass: ''
            },
            valid: false,
            touched: false
        },
        fechaHoraPago: {
            placeholder: 'dd/MM/yyyy',
            value: '',
            validation: {
                required: true,
                errorMsg: "",
                invalidClass: ''
            },
            valid: false,
            touched: false
        },
        referencia: {
            placeholder: '000000',
            value: '',
            onlyNumbers: true,
            validation: {
                required: true,
                errorMsg: "",
                invalidClass: ''
            },
            valid: false,
            touched: false
        },
        urlImagen: {
            placeholder: '',
            value: '',
            validation: {
                required: false,
                errorMsg: "",
                invalidClass: ''
            },
            valid: true,
            touched: false
        }
    })
    const [captchaCorrecto, setCaptchaCorrecto] = useState(false)
    const [gToken, setGToken] = useState("");
    const [esValido, setEsValido] = useState(false)
    const [estaCargando, setEstaCargando] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null);

    const { addToast } = useToasts();

    const handleLoaded = _ => {
        window.grecaptcha.ready(_ => {
            window.grecaptcha
                .execute("6LcIIdQaAAAAAMsk3arny50T9bEdGJrpHLdT14NK", { action: "submit" })
                .then(token => {
                    setCaptchaCorrecto(true);
                    setGToken(token);
                })
        })
    }

    const handleFileInput = (e) => {
        if (e.target.files[0].size > 5242880) {
            e.target.value = null;
            addToast('La imagen es muy grande. Máximo permitido (5MB).', { appearance: 'warning', autoDismiss: true });
            return;
        }

        setSelectedFile(e.target.files[0]);
    }

    const cambiarValor = (id, valor) => {
        const copiaForm = { ...form }
        copiaForm[id].value = valor

        const validacion = validarInput(copiaForm[id].value, copiaForm[id].validation, id);
        copiaForm[id].valid = validacion.isValid;
        copiaForm[id].validation.errorMsg = validacion.isValid ? "" : validacion.msgError;
        copiaForm[id].validation.invalidClass = validacion.isValid ? '' : 'input-invalid'

        let htmlFormEsValido = true;
        for (let inputIdentifier in copiaForm) {
            htmlFormEsValido = copiaForm[inputIdentifier].valid && htmlFormEsValido;
        }

        setEsValido(htmlFormEsValido);

        if (copiaForm[id].onlyNumbers) {
            const re = /^[0-9\b]+$/;
            if (valor === '' || re.test(valor)) {
                setForm({ ...copiaForm })
            }
        }
        else
            setForm({ ...copiaForm })
    }

    const cambiarPlaceholderMoneda = (id, valor) => {
        const copiaForm = { ...form }
        let placeholder = "";
        let prefijo = '';

        if (valor === "CRC") {
            placeholder = "₡ 0.00";
            prefijo = "₡";
        }
        else if (valor === "USD") {
            placeholder = "$ 0.00";
            prefijo = "$";
        }

        copiaForm['totalPagado'].placeholder = placeholder;
        copiaForm['totalPagado'].prefijo = prefijo;

        setForm({ ...copiaForm })
    }

    const handleChange = (e, id) => {
        cambiarValor(id, e.target.value)
        switch (id) {
            case 'moneda':
                cambiarPlaceholderMoneda(id, e.target.value)
                break;
            default:
                break;
        }
    }

    const submitPago = async (e) => {
        e.preventDefault();

        if (!captchaCorrecto) {
            addToast(`El formulario ha expirado o es incorrecto, por favor recargue la página o contacte al administrador del sitio.`, { appearance: 'error', autoDismiss: true });
            return;
        }

        if (!esValido) {
            const copiaForm = { ...form };
            for (let inputIdentifier in form) {
                const validacion = validarInput(form[inputIdentifier].value, form[inputIdentifier].validation, inputIdentifier);
                copiaForm[inputIdentifier].valid = validacion.isValid;
                copiaForm[inputIdentifier].validation.errorMsg = validacion.isValid ? "" : validacion.msgError;
                copiaForm[inputIdentifier].validation.invalidClass = validacion.isValid ? '' : 'input-invalid'
            }
            setForm({ ...copiaForm });
            console.log(form);
            addToast('Debe verificar los campos requeridos para continuar.', { appearance: 'warning', autoDismiss: true });
            return;
        }

        setEstaCargando(true)

        var uploadedFile = null;
        if (selectedFile) {
            try {
                uploadedFile = await uploadFile(selectedFile, config);
            } catch (e) {
                addToast('Ocurrió un error al intentar subir la imagen.', { appearance: 'warning', autoDismiss: true });
            }
        }

        const payload = {
            numeroContrato: form.numeroContrato.value,
            nombreCompleto: form.nombreCompleto.value,
            referencia: form.referencia.value,
            email: form.email.value,
            moneda: form.moneda.value,
            totalPagado: form.totalPagado.value,
            fechaQueReporta: form.fechaHoraPago.value,
            comentarios: form.comentario.value,
            urlImagen: uploadedFile ? uploadedFile.location : "",
            captchaToken: gToken
        }

        servicioExterno.reportarPago(payload).then((resp) => {
            addToast('Reporte enviado.', { appearance: 'success', autoDismiss: true });
            setEstaCargando(false)
            setForm({
                numeroContrato: {
                    placeholder: '0000000',
                    value: '',
                    onlyNumbers: true,
                    validation: {
                        required: true,
                        isNumeric: true,
                        errorMsg: "",
                    },
                    valid: false,
                    touched: false
                },
                nombreCompleto: {
                    placeholder: 'Juanito Peréz Rosales',
                    value: '',
                    validation: {
                        required: true,
                        errorMsg: "",
                        invalidClass: ''
                    },
                    valid: false,
                    touched: false
                },
                email: {
                    placeholder: 'juanito.perez@email.com',
                    value: '',
                    validation: {
                        required: false,
                        errorMsg: "",
                        invalidClass: ''
                    },
                    valid: true,
                    touched: false
                },
                comentario: {
                    placeholder: 'Comentarios',
                    value: '',
                    validation: {
                        required: false,
                        errorMsg: "",
                        invalidClass: ''
                    },
                    valid: true,
                    touched: false
                },
                moneda: {
                    placeholder: '',
                    value: 'CRC',
                    validation: {
                        required: false,
                        errorMsg: "",
                        invalidClass: ''
                    },
                    valid: true,
                    touched: false
                },
                totalPagado: {
                    placeholder: '₡ 0.00',
                    prefijo: '₡',
                    value: '',
                    validation: {
                        required: true,
                        errorMsg: "",
                        isCurrency: true,
                        invalidClass: ''
                    },
                    valid: false,
                    touched: false
                },
                fechaHoraPago: {
                    placeholder: 'dd/MM/yyyy',
                    value: '',
                    validation: {
                        required: true,
                        errorMsg: "",
                        invalidClass: ''
                    },
                    valid: false,
                    touched: false
                },
                referencia: {
                    placeholder: '000000',
                    value: '',
                    onlyNumbers: true,
                    validation: {
                        required: true,
                        errorMsg: "",
                        invalidClass: ''
                    },
                    valid: false,
                    touched: false
                },
                urlImagen: {
                    placeholder: '',
                    value: '',
                    validation: {
                        required: false,
                        errorMsg: "",
                        invalidClass: ''
                    },
                    valid: true,
                    touched: false
                }
            })
            setTimeout(function () { window.location.reload(); }, 2000);

        }).catch((error) => {
            let msg = "";
            console.log(error.error)
            if (error.error)
                msg = error.error;
            addToast(`Ha ocurrido un error al registrar su pago.`, { appearance: 'error', autoDismiss: true });
            setEstaCargando(false)
        })
    }

    useEffect(() => {
        // Add reCaptcha
        const script = document.createElement("script")
        script.src = "https://www.google.com/recaptcha/api.js?render=6LcIIdQaAAAAAMsk3arny50T9bEdGJrpHLdT14NK"
        script.addEventListener("load", handleLoaded)
        document.body.appendChild(script)
    }, [])
    return (
        <div className="min-w-screen min-h-screen md:bg-gray-900 md:flex items-center justify-end px-5 py-5 bg-no-repeat bg-cover bg-left-top" style={{ backgroundImage: `url(${background})` }}>
            <div className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl lg:w-1/3 md:w-3/4 overflow-hidden">

                <form className="md:flex w-full" onSubmit={submitPago}>
                    <div className="w-full py-5 px-5 md:px-10">
                        <div className="flex flex-col justify-center items-center mb-5">
                            <img className='w-24' src={logo} alt='logo ticolinea' />
                            <h1 className="font-bold text-3xl text-gray-900">Reportar mi pago</h1>
                            <p>Completa el siguiente Formulario</p>
                        </div>
                        <div>
                            {estaCargando && (
                                <div className="my-32 text-center">
                                    <h2 className="text-lg">¡Gracias!</h2>
                                    <h2 className="text-2xl">Estamos reportando su pago...</h2>
                                </div>
                            )}
                            {!estaCargando && (
                                <div>
                                    <div className="lg:flex -mx-3">
                                        <div className="w-full lg:w-1/2 px-3 mb-2">
                                            <label htmlFor="" className="text-xs font-semibold px-1">Número de Contrato</label>
                                            <div className="flex">
                                                <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-account-outline text-gray-400 text-lg"></i></div>
                                                <input type="text" className={`w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500 ${form.numeroContrato.validation.invalidClass}`}
                                                    placeholder={form.numeroContrato.placeholder} value={form.numeroContrato.value} onChange={(event) => handleChange(event, "numeroContrato")} />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-1/2 px-3 mb-2">
                                            <label htmlFor="referencia" className="text-xs font-semibold px-1">Número de referencia</label>
                                            <div className="flex">
                                                <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-account-outline text-gray-400 text-lg"></i></div>
                                                <input id="referencia" type="text" className={`w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500 ${form.referencia.validation.invalidClass}`}
                                                    placeholder={form.referencia.placeholder} value={form.referencia.value} onChange={(event) => handleChange(event, "referencia")} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex -mx-3">
                                        <div className="w-full px-3 mb-2">
                                            <label htmlFor="nombreCompleto" className="text-xs font-semibold px-1">Nombre Completo</label>
                                            <div className="flex">
                                                <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-email-outline text-gray-400 text-lg"></i></div>
                                                <input id="nombreCompleto" type="text" className={`w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500 ${form.nombreCompleto.validation.invalidClass}`}
                                                    placeholder={form.nombreCompleto.placeholder} value={form.nombreCompleto.value} onChange={(event) => handleChange(event, "nombreCompleto")} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex -mx-3">
                                        <div className="w-full px-3 mb-2">
                                            <label htmlFor="email" className="text-xs font-semibold px-1">Correo Electrónico</label>
                                            <div className="flex">
                                                <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-email-outline text-gray-400 text-lg"></i></div>
                                                <input id="email" type="email" className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                                                    placeholder={form.email.placeholder} value={form.email.value} onChange={(event) => handleChange(event, "email")} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="lg:flex -mx-3">
                                        <div className="w-full lg:w-1/3 px-3 mb-2">
                                            <label htmlFor="" className="text-xs font-semibold px-1">Moneda</label>
                                            <div className="flex">
                                                <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-account-outline text-gray-400 text-lg"></i></div>
                                                <select id="moneda" className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                                                    value={form.moneda.value} onChange={(event) => handleChange(event, "moneda")}>
                                                    <option value="CRC">₡</option>
                                                    <option value="USD">$</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-3/4 px-3 mb-2">
                                            <label htmlFor="" className="text-xs font-semibold px-1">Monto del comprobante</label>
                                            <div className="flex">
                                                <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-account-outline text-gray-400 text-lg"></i></div>
                                                <CurrencyhtmlFormat id="totalPagado" value={form.totalPagado.value} thousandSeparator={true} prefix={form.totalPagado.prefijo} placeholder={form.totalPagado.placeholder}
                                                    className={`w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500 ${form.totalPagado.validation.invalidClass}`} onValueChange={(values) => {
                                                        const { FormattedValue, value } = values;
                                                        cambiarValor("totalPagado", value)
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex -mx-3">
                                        <div className="w-full px-3 mb-2">
                                            <label htmlFor="" className="text-xs font-semibold px-1">Fecha en que realizó la transferencia</label>
                                            <div className="flex">
                                                <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-lock-outline text-gray-400 text-lg"></i></div>
                                                <Datetime id="fechaHoraPago" className={`pago-externo bg-white w-full -ml-10 pl-10 pr-3 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500 ${form.fechaHoraPago.validation.invalidClass}`} initialValue={moment(new Date()).add(-30, 'day')} dateFormat="DD/MM/YYYY" timeFormat={false} locale="es"
                                                    value={moment(form.fechaHoraPago.value)} closeOnSelect={true} utc={true} onChange={(date) => {
                                                        cambiarValor('fechaHoraPago', date.format())
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex -mx-3">
                                        <div className="w-full px-3 mb-2">
                                            <label htmlFor="" className="text-xs font-semibold px-1">Subir el comprobante de pago</label>
                                            <div className="flex">
                                                <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-lock-outline text-gray-400 text-lg"></i></div>
                                                <input type="file" accept="image/*" onChange={handleFileInput} className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" placeholder="************" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex -mx-3">
                                        <div className="w-full px-3 mb-2">
                                            <label htmlFor="" className="text-xs font-semibold px-1">Comentarios (Opcional)</label>
                                            <div className="flex">
                                                <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-lock-outline text-gray-400 text-lg"></i></div>
                                                <textarea maxLength="800" style={{ resize: 'none' }} className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                                                    placeholder={form.comentario.placeholder} value={form.comentario.value} onChange={(event) => handleChange(event, "comentario")}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="flex -mx-3">
                                <div className="w-full px-3 mb-2">
                                    <div
                                        className="g-recaptcha"
                                        data-sitekey="_reCAPTCHA_site_key_"
                                        data-size="invisible"></div>
                                </div>

                            </div>
                            {estaCargando && (
                                <div className="grid md:grid-cols-1 gap-2">
                                    <XsSpinner />
                                </div>
                            )}
                            {!estaCargando && (
                                <div className="flex -mx-3">
                                    <div className="w-full px-3 mb-5">
                                        <button className="block w-full max-w-xs mx-auto bg-indigo-500 hover:bg-indigo-700 focus:bg-indigo-700 text-white rounded-lg px-3 py-3 font-semibold">ENVIAR REPORTE</button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ReportePagoExterno
