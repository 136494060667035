import React, { useState, useEffect } from 'react'

import Layout from '../../components/Layout'
import Container from "../../components/UI/ContainerCard/Container";
import Table from "../../components/UI/Table";
import Spinner from '../../components/UI/Spinner/Spinner'
import XsSpinner from '../../components/UI/Spinner/XsSpinner';
import CurrencyFormat from 'react-currency-format';
import { validarInput } from "../../helpers/validaciones";
import Modal from 'react-modal';

import servicios from '../../services/servicios'

import { useToasts } from 'react-toast-notifications';

const Servicio = () => {
    const [estaCargando, setEstaCargando] = useState(true);
    const [data, setData] = useState([])
    const { addToast } = useToasts();
    const [esValido, setEsValido] = useState(false)
    const [esActualizacion, setEsActualizacion] = useState(false)
    const [modalDetalleIsOpen, setModalDetalleIsOpen] = useState(false)
    const [servicioId, setServicioId] = useState(0)
    const defServicios = [
        { titulo: "Código", data: 'codigoInterno', tipo: 's' },
        { titulo: "Nombre", data: 'descripcionCorta', tipo: 's' },
        { titulo: "Precio", data: 'precioUnitario', tipo: 'c' },
        { titulo: "Acciones", data: 'idServicio', tipo: 'v' }
    ];
    const customStyles = {
        overlay: {
            'z-index': '9999999',
            'background-color': 'rgba(0, 0, 0, 0.4)'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            background: 'rgba(255, 255, 255,0.1)',
            border: '0'
        }
    };
    const [defForm, setDefForm] = useState({
        codigoInterno: {
            placeholder: '000-Servicio',
            value: '',
            validation: {
                required: true,
                errorMsg: "",
                invalidClass: ''
            },
            valid: false,
            touched: false
        },
        descripcionCorta: {
            placeholder: "Descripción de Servicio",
            value: '',
            validation: {
                required: true,
                errorMsg: "",
                invalidClass: ''
            },
            valid: false,
            touched: false
        },
        descripcionLarga: {
            placeholder: '',
            value: '',
            validation: {
                required: false,
                errorMsg: "",
            },
            valid: true,
            touched: false
        },
        precio: {
            placeholder: '₡ 0.00',
            prefijo: '₡',
            value: '',
            validation: {
                required: true,
                errorMsg: "",
                invalidClass: ''
            },
            valid: false,
            touched: false
        },
        esProducto: {
            value: 0,
            validation: {
                required: false
            },
            valid: true,
            touched: false
        },
        esCredito: {
            value: 0,
            validation: {
                required: false
            },
            valid: true,
            touched: false
        },
        aplicaCanon911: {
            value: 0,
            validation: {
                required: false
            },
            valid: true,
            touched: false
        },
        aplicaCanonCruzRoja: {
            value: 0,
            validation: {
                required: false
            },
            valid: true,
            touched: false
        },
        mesesCredito: {
            placeholder: '0',
            value: '0',
            onlyNumbers: true,
            validation: {
                required: true,
                isNumeric: true,
                errorMsg: "",
            },
            valid: false,
            touched: false
        },
        moneda: {
            placeholder: '',
            value: 'CRC',
            validation: {
                required: false,
                errorMsg: "",
                invalidClass: ''
            },
            valid: true,
            touched: false
        }
    })
    const obtenerServicios = () => {
        setEstaCargando(true)
        servicios.obtener().then((resp) => {
            if (resp.data) {
                setData(resp.data)
            }
            setEstaCargando(false)
        }).catch((error) => {
            let msg = "";
            if (error.error)
                msg = error.error;
            addToast(`Ha ocurrido un error al obtener servicios. ${msg}`, { appearance: 'error', autoDismiss: true });
            setEstaCargando(false)
        });
    };

    const cancelarActualizacion = () => {
        setServicioId(0);
        setDefForm({
            codigoInterno: {
                placeholder: '000-Servicio',
                value: '',
                validation: {
                    required: true,
                    errorMsg: "",
                    invalidClass: ''
                },
                valid: false,
                touched: false
            },
            descripcionCorta: {
                placeholder: "Descripción de Servicio",
                value: '',
                validation: {
                    required: true,
                    errorMsg: "",
                    invalidClass: ''
                },
                valid: false,
                touched: false
            },
            descripcionLarga: {
                placeholder: '',
                value: '',
                validation: {
                    required: false,
                    errorMsg: "",
                },
                valid: true,
                touched: false
            },
            precio: {
                placeholder: '₡ 0.00',
                prefijo: '₡',
                value: '',
                validation: {
                    required: true,
                    errorMsg: "",
                    invalidClass: ''
                },
                valid: false,
                touched: false
            },
            esProducto: {
                value: 0,
                validation: {
                    required: false
                },
                valid: true,
                touched: false
            },
            esCredito: {
                value: 0,
                validation: {
                    required: false
                },
                valid: true,
                touched: false
            },
            aplicaCanon911: {
                value: 0,
                validation: {
                    required: false
                },
                valid: true,
                touched: false
            },
            aplicaCanonCruzRoja: {
                value: 0,
                validation: {
                    required: false
                },
                valid: true,
                touched: false
            },
            mesesCredito: {
                placeholder: '0',
                value: '0',
                onlyNumbers: true,
                validation: {
                    required: true,
                    isNumeric: true,
                    errorMsg: "",
                },
                valid: false,
                touched: false
            },
            moneda: {
                placeholder: '',
                value: 'CRC',
                validation: {
                    required: false,
                    errorMsg: "",
                    invalidClass: ''
                },
                valid: true,
                touched: false
            }
        });
        setEsActualizacion(false);
    }

    const handleVerServicio = (idServicio) => {
        cancelarActualizacion();
        setModalDetalleIsOpen(true)
        if (idServicio) {
            setServicioId(idServicio);
            var servicioSeleccionado = data.filter(x => x.idServicio === idServicio);
            if (servicioSeleccionado) {
                setEsActualizacion(true);
                var servData = servicioSeleccionado[0];

                if (servData.codigoInterno)
                    cambiarValor('codigoInterno', servData.codigoInterno);
                if (servData.precioUnitario)
                    cambiarValor('precio', servData.precioUnitario);
                if (servData.descripcionCorta)
                    cambiarValor('descripcionCorta', servData.descripcionCorta);
                if (servData.descripcionLarga)
                    cambiarValor('descripcionLarga', servData.descripcionLarga);
                cambiarValor('esProducto', servData.esProducto);
                cambiarValor('esCredito', servData.esCredito);
                cambiarValor('aplicaCanon911', servData.aplicaCanon911);
                cambiarValor('aplicaCanonCruzRoja', servData.aplicaCanonCruzRoja);
                cambiarValor('mesesCredito', servData.mesesCredito);
                cambiarValor('moneda', servData.moneda);
                cambiarPlaceholderMoneda('moneda', servData.moneda);
            }
        }
    }

    const cambiarPlaceholderMoneda = (id, valor) => {
        const copiaCl = { ...defForm }
        let placeholder = "";
        let prefijo = '';

        if (valor === "CRC") {
            placeholder = "₡ 0.00";
            prefijo = "₡";
        }
        else if (valor === "USD") {
            placeholder = "$ 0.00";
            prefijo = "$";
        }

        copiaCl['precio'].placeholder = placeholder;
        copiaCl['precio'].prefijo = prefijo;


        setDefForm({ ...copiaCl })
    }

    const cambiarValor = (id, valor) => {
        const copiaDefForm = { ...defForm }
        copiaDefForm[id].value = valor

        try {
            const validacion = validarInput(copiaDefForm[id].value, copiaDefForm[id].validation, id);
            copiaDefForm[id].valid = validacion.isValid;
            copiaDefForm[id].validation.errorMsg = validacion.isValid ? "" : validacion.msgError;
            copiaDefForm[id].validation.invalidClass = validacion.isValid ? '' : 'input-invalid'
        } catch { console.log("Error al validar campo " + id) }


        let formEsValido = true;
        for (let inputIdentifier in copiaDefForm) {
            formEsValido = copiaDefForm[inputIdentifier].valid && formEsValido;
        }

        setEsValido(formEsValido);

        if (copiaDefForm[id].onlyNumbers) {
            const re = /^[0-9\b]+$/;
            if (valor === '' || re.test(valor)) {
                setDefForm({ ...copiaDefForm })
            }
        }
        else
            setDefForm({ ...copiaDefForm })
    }

    const handleChange = (e, id) => {
        if (e.target.type === 'checkbox') {
            const value = e.target.checked ? 1 : 0;
            cambiarValor(id, value);
        }
        else if (id === "moneda") {
            cambiarPlaceholderMoneda(id, e.target.value)
            cambiarValor(id, e.target.value);
        }
        else
            cambiarValor(id, e.target.value);
    };

    const submitServicio = (e) => {
        e.preventDefault();

        if (!esValido) {
            const copiaDefForm = { ...defForm };
            for (let inputIdentifier in defForm) {
                console.log(inputIdentifier)
                const validacion = validarInput(defForm[inputIdentifier].value, defForm[inputIdentifier].validation, inputIdentifier);
                copiaDefForm[inputIdentifier].valid = validacion.isValid;
                copiaDefForm[inputIdentifier].validation.errorMsg = validacion.isValid ? "" : validacion.msgError;
                copiaDefForm[inputIdentifier].validation.invalidClass = validacion.isValid ? '' : 'input-invalid'
            }
            setDefForm({ ...copiaDefForm });
            addToast('Debe verificar los campos requeridos para continuar.', { appearance: 'warning', autoDismiss: true });
            return;
        }

        setEstaCargando(true);

        const payload = {
            "CodigoInterno": defForm.codigoInterno.value,
            "PrecioUnitario": defForm.precio.value.replace("₡", "").replace("$", ""),
            "DescripcionCorta": defForm.descripcionCorta.value,
            "DescripcionLarga": defForm.descripcionLarga.value,
            "AplicaCanon911": defForm.aplicaCanon911.value,
            "MesesCredito": defForm.mesesCredito.value,
            "AplicaCanonCruzRoja": defForm.aplicaCanonCruzRoja.value,
            "EsCredito": defForm.esCredito.value,
            "EsProducto": defForm.esProducto.value,
            "TarifaIva": 0,
            "Moneda": defForm.moneda.value
        }

        servicios.registrarServicio(payload).then((resp) => {
            addToast('Servicio registrado.', { appearance: 'success', autoDismiss: true });
            obtenerServicios();
            setDefForm({
                codigoInterno: {
                    placeholder: '000-Servicio',
                    value: '',
                    validation: {
                        required: true,
                        errorMsg: "",
                        invalidClass: ''
                    },
                    valid: false,
                    touched: false
                },
                descripcionCorta: {
                    placeholder: "Descripción de Servicio",
                    value: '',
                    validation: {
                        required: true,
                        errorMsg: "",
                        invalidClass: ''
                    },
                    valid: false,
                    touched: false
                },
                descripcionLarga: {
                    placeholder: '',
                    value: '',
                    validation: {
                        required: false,
                        errorMsg: "",
                    },
                    valid: true,
                    touched: false
                },
                precio: {
                    placeholder: '₡ 0.00',
                    prefijo: '₡',
                    value: '',
                    validation: {
                        required: true,
                        errorMsg: "",
                        invalidClass: ''
                    },
                    valid: false,
                    touched: false
                },
                esProducto: {
                    value: 0,
                    validation: {
                        required: false
                    },
                    valid: true,
                    touched: false
                },
                esCredito: {
                    value: 0,
                    validation: {
                        required: false
                    },
                    valid: true,
                    touched: false
                },
                aplicaCanon911: {
                    value: 0,
                    validation: {
                        required: false
                    },
                    valid: true,
                    touched: false
                },
                aplicaCanonCruzRoja: {
                    value: 0,
                    validation: {
                        required: false
                    },
                    valid: true,
                    touched: false
                },
                mesesCredito: {
                    placeholder: '0',
                    value: '0',
                    onlyNumbers: true,
                    validation: {
                        required: true,
                        isNumeric: true,
                        errorMsg: "",
                    },
                    valid: false,
                    touched: false
                },
                moneda: {
                    placeholder: '',
                    value: 'CRC',
                    validation: {
                        required: false,
                        errorMsg: "",
                        invalidClass: ''
                    },
                    valid: true,
                    touched: false
                }
            });
            setEsActualizacion(false)
            setEsValido(false)
        }).catch((error) => {
            let msg = "";
            console.log(error.error)
            if (error.error)
                msg = error.error;
            addToast(`Ha ocurrido un error al registrar servicio. ${msg}`, { appearance: 'error', autoDismiss: true });
            setEstaCargando(false)
        })
    }

    const eliminarServicio = () => {
        if (servicioId === 0) return;
        if (window.confirm("¿Seguro que desea eliminar el servicio seleccionado?")) {
            servicios.eliminarServicio(servicioId).then(() => {
                obtenerServicios();
                cancelarActualizacion();
                setModalDetalleIsOpen(false);
                addToast('Servicio eliminado', { appearance: 'success', autoDismiss: true });
            }).catch((error) => {
                let msg = "";
                console.log(error.error)
                if (error.error)
                    msg = error.error;
                addToast(`Ha ocurrido un error al registrar servicio. ${msg}`, { appearance: 'error', autoDismiss: true });
            });
        }
    }

    useEffect(() => {
        obtenerServicios();
    }, [])
    return (
        <>
            <Layout titulo="Servicios" subtitulo="Mantenimiento &gt; Servicios" >
                <div className="w-11/12">
                    <div className="flex items-start justify-center">
                        <Container mostrartitulo="false">
                            <div className="grid md:grid-cols-5 m-3">
                                <div className="mt-5 text-sm">
                                    <button type="button" onClick={obtenerServicios} className="btn transition bg-green-500 hover:bg-green-700">Refrescar</button>
                                </div>
                                <div className="ml-2 mt-5 text-sm">
                                    <button type="button" onClick={() => setModalDetalleIsOpen(true)} className="btn transition bg-blue-500 hover:bg-blue-700">Nuevo Servicio</button>
                                </div>
                            </div>
                            {estaCargando && <Spinner />}
                            {!estaCargando &&
                                <Table columnas={defServicios} filas={data} verRegistro={handleVerServicio} />}
                        </Container>
                    </div>
                </div>
            </Layout>
            <Modal
                isOpen={modalDetalleIsOpen}
                onRequestClose={() => setModalDetalleIsOpen(false)}
                style={customStyles}
                contentLabel=""
            >
                <div className="rounded-md flex items-start justify-center ml-5">
                    <Container mostrartitulo="false">

                        <form className="mt-2 mx-2" onSubmit={submitServicio}>
                            <div className="float-right pb-2"><button onClick={() => setModalDetalleIsOpen(false)}><i className="far fa-times-circle text-3xl"></i></button></div>
                            <h2 className="text-xl font-medium"> {esActualizacion ? "Actualización de Servicio/Producto" : "Agregar Nuevo Servicio"}</h2>
                            <div className="grid md:grid-cols-2">
                                <div className="mt-5 text-sm">
                                    <label htmlFor="codigoInterno" className="block text-black">Código Interno</label>
                                    <input type="text" id="codigoInterno" className={`rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full ${defForm.codigoInterno.validation.invalidClass}`}
                                        placeholder={defForm.codigoInterno.placeholder} value={defForm.codigoInterno.value} onChange={(event) => handleChange(event, "codigoInterno")} />
                                </div>
                                <div className="mt-5 ml-2 text-sm">
                                    <label htmlFor="moneda" className="block text-black">Moneda</label>
                                    <select id="moneda" className="rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full"
                                        value={defForm.moneda.value} onChange={(event) => handleChange(event, "moneda")}>
                                        <option value="CRC">₡ - Colones</option>
                                        <option value="USD">$ - Dolares</option>
                                    </select>
                                </div>
                            </div>
                            <div className="grid md:grid-cols-1">
                                <div className="mt-5 text-sm">
                                    <label htmlFor="descripcionCorta" className="block text-black">Nombre de Servicio</label>
                                    <input type="text" id="descripcionCorta" className={`rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full ${defForm.descripcionCorta.validation.invalidClass}`}
                                        placeholder={defForm.descripcionCorta.placeholder} value={defForm.descripcionCorta.value} onChange={(event) => handleChange(event, "descripcionCorta")} />
                                </div>
                            </div>
                            <div className="grid md:grid-cols-1">
                                <div className="mt-5 text-sm">
                                    <label htmlFor="descripcionLarga" className="block text-black">Descripción Larga</label>
                                    <input type="text" id="descripcionLarga" className={`rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full`}
                                        placeholder={defForm.descripcionLarga.placeholder} value={defForm.descripcionLarga.value} onChange={(event) => handleChange(event, "descripcionLarga")} />
                                </div>
                            </div>
                            <div className="grid md:grid-cols-1">
                                <div className="mt-5 text-sm">
                                    <label htmlFor="precio" className="block text-black">Precio</label>
                                    <CurrencyFormat id="precio" value={defForm.precio.value} thousandSeparator={true} prefix={defForm.precio.prefijo} placeholder={defForm.precio.placeholder}
                                        className={`rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full ${defForm.precio.validation.invalidClass}`} onValueChange={(values) => {
                                            const { formattedValue, value } = values;
                                            cambiarValor("precio", value)
                                        }} />
                                </div>
                            </div>
                            <div className="grid md:grid-cols-4">
                                <div className="mt-5 text-sm">
                                    <input
                                        className="mr-2 mt-2 form-checkbox h-5 w-5 text-gray-600"
                                        name="esProducto"
                                        type="checkbox"
                                        checked={defForm.esProducto.value === 1}
                                        onChange={(event) => handleChange(event, "esProducto")} />
                                    <label htmlFor="esProducto" className="text-black">¿Es un Producto?</label>
                                </div>
                                <div className="mt-5 text-sm border-l-2 pl-3">
                                    <input
                                        className="mr-2 mt-2 form-checkbox h-5 w-5 text-gray-600"
                                        name="esCredito"
                                        type="checkbox"
                                        checked={defForm.esCredito.value === 1}
                                        onChange={(event) => handleChange(event, "esCredito")} />
                                    <label htmlFor="esCredito" className="text-black">¿Es a Crédito?</label>
                                </div>
                                <div className="mt-5 text-sm border-l-2 pl-3">
                                    <input
                                        className="mr-2 mt-2 form-checkbox h-5 w-5 text-gray-600"
                                        name="aplicaCanon911"
                                        type="checkbox"
                                        checked={defForm.aplicaCanon911.value === 1}
                                        onChange={(event) => handleChange(event, "aplicaCanon911")} />
                                    <label htmlFor="aplicaCanon911" className="text-black">¿Aplica Canon 911?</label>
                                </div>
                                <div className="mt-5 text-sm border-l-2 pl-3">
                                    <input
                                        className="mr-2 mt-2 form-checkbox h-5 w-5 text-gray-600"
                                        name="aplicaCanonCruzRoja"
                                        type="checkbox"
                                        checked={defForm.aplicaCanonCruzRoja.value === 1}
                                        onChange={(event) => handleChange(event, "aplicaCanonCruzRoja")} />
                                    <label htmlFor="aplicaCanonCruzRoja" className="text-black">¿Aplica Canon Cruz Roja?</label>
                                </div>
                            </div>
                            {defForm.esCredito.value === 1 &&
                                <div className="grid md:grid-cols-4">
                                    <div className="mt-5 text-sm">
                                        <label htmlFor="mesesCredito" className="block text-black">Meses del Crédito</label>
                                        <input type="text" id="mesesCredito" className={`rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full`}
                                            placeholder={defForm.mesesCredito.placeholder} value={defForm.mesesCredito.value} onChange={(event) => handleChange(event, "mesesCredito")} />
                                    </div>
                                </div>
                            }
                            {estaCargando && (
                                <div className="grid md:grid-cols-1 gap-2">
                                    <XsSpinner />
                                </div>
                            )}
                            {!estaCargando && (
                                <div className="grid md:grid-cols-2 gap-2 content-end">
                                    <div className="m-5 text-sm">
                                        <button className="btn transition bg-green-500 hover:bg-green-700" type="submit">
                                            {!esActualizacion &&
                                                <span>Registrar Servicio</span>
                                            }
                                            {esActualizacion &&
                                                <span>Actualizar Servicio</span>
                                            }
                                        </button>
                                    </div>
                                    <div className="m-5 text-sm ">
                                        {esActualizacion &&
                                            <button className="btn transition bg-gray-500" type="button" onClick={cancelarActualizacion}>
                                                <span>Cancelar Actualización</span>
                                            </button>
                                        }
                                    </div>
                                </div>
                            )}
                            {(!estaCargando && esActualizacion) && (
                                <div className="content-end">
                                    <div className="mb-2 text-sm text-center">
                                        <button className="bg-white underline text-base text-red-600" onClick={eliminarServicio} type="button">
                                            <i class="fas fa-trash-alt mr-2"></i>
                                            <span>Eliminar Servicio</span>
                                        </button>
                                    </div>
                                </div>
                            )}
                        </form>
                    </Container>
                </div>
            </Modal>
        </>
    )
}

export default Servicio
