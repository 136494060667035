import http from '../axios'
const ServicioPago = {
    obtener(numeroContrato, nombre, desde, hasta, maxDatos, pagina) {
        var query = '';
        if (numeroContrato)
            query += `numeroContrato=${numeroContrato}&`;
        if (nombre)
            query += `nombre=${nombre}&`;
        if (desde)
            query += `desde=${desde}&`;
        if (hasta)
            query += `hasta=${hasta}&`;
        if (maxDatos)
            query += `maxDatos=${maxDatos}&`;
        if (pagina)
            query += `pagina=${pagina}&`;

        return http.get(`/Pago/Obtener?${query}`);
    },
    obtenerExterno(numeroContrato, nombre, desde, hasta, maxDatos, pagina) {
        var query = '';
        if (numeroContrato)
            query += `numeroContrato=${numeroContrato}&`;
        if (nombre)
            query += `nombre=${nombre}&`;
        if (desde)
            query += `desde=${desde}&`;
        if (hasta)
            query += `hasta=${hasta}&`;
        if (maxDatos)
            query += `maxDatos=${maxDatos}&`;
        if (pagina)
            query += `pagina=${pagina}&`;

        return http.get(`/Pago/ObtenerExterno?${query}`);
    },
    obtenerReporteExterno(idReporte) {
        return http.get(`/Pago/ObtenerReporteExterno?idReporte=${idReporte}`);
    },
    registrarPago(payload) {
        return http.post('/Pago/Registrar', payload);
    },
    aprobarPago(payload) {
        return http.post('/Pago/AprobarComprobante', payload);
    },
    eliminarPago(id) {
        return http.delete(`/Pago/Eliminar?id=${id}`)
    }
}

export default ServicioPago
