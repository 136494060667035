import React, {useEffect, useState} from 'react'
import Navbar from '../../components/Navbar'
import Sidebar from '../../components/Sidebar'
import SummaryChart from "./components/summaryChart";
import Container from "../../components/UI/ContainerCard/Container";
import Table from "../../components/UI/Table";
import Footer from "../../components/UI/Footer";

//servicios
import ServicioFacturas from '../../services/facturas';

import moment from 'moment';
import 'moment/locale/es';
import 'moment-timezone';

const Dashboard = () => {
    const fecDesde = moment.tz(moment(new Date()).add(-30, 'day'), 'America/Costa_Rica').format();
    const fecHasta = moment.tz('America/Costa_Rica').format();

    const [clienteMorosos, setClienteMorosos] = useState(0);
    const [totalColones, setTotalColones] = useState("₡ 0.00");
    const [totalDolares, setTotalDolares] = useState("$ 0.00");
    const [dataFacturas, setDataFacturas] = useState([]);

    const accionVerFacturas=()=>{
        window.open("/listafacturas","_self");
    }
    useEffect(()=>{
        ServicioFacturas.obtenerCantidadClientesMorosos().then((response)=>{
            setClienteMorosos(response.data);
        });
        ServicioFacturas.obtenerNoPagas(fecDesde,fecHasta,10,1).then((response)=>{
            setDataFacturas(response.data.facturasEncabezado);
            setTotalColones(response.data.totalColones);
            setTotalDolares(response.data.totalDolares);
        });
    });
    const columnas = [
        { titulo: "Contrato", data: 'numeroContrato', tipo: 's' },
        { titulo: "# Factura", data: 'idFactura', tipo: 's' },
        { titulo: "Nombre", data: 'nombre', tipo: 's' },
        { titulo: "Fecha de Emisión", data: 'fechaEmision', tipo: 's' },
        { titulo: "Mes de Factura", data: 'mesFacturado', tipo: 's' },
        { titulo: "Total", data: 'totalVenta', tipo: 'c' },
        { titulo: "Acciones", data: 'idCliente', tipo: 'v' }
    ]
    const verRegistro = (data) => {
        window.open(`/cliente/${data}`,"_self");
    }
    return (
        <>
            <Sidebar />
            <div className="relative md:ml-52 bg-blueGray-100">
                <Navbar titulo="RESUMEN DE ACTIVIDAD" />
                <div className="relative bg-blue-600 md:pt-32 pb-32 pt-12">
                    <div className="px-4 md:px-10 mx-auto w-full">
                        <div>
                            <div className="flex flex-wrap">
                                <SummaryChart Title="CLIENTES MOROSOS" BgColor="bg-yellow-500" Icon="fas fas fa-sad-cry" Valor={clienteMorosos} Titulo="Total de clientes morosos" />
                                <SummaryChart Title="TOTAL ₡ POR COBRAR" BgColor="bg-red-500" Icon="fas fas fa-euro-sign" Valor={totalColones} Titulo="Total de ₡ de facturas no pagadas" />
                                <SummaryChart Title="TOTAL $ POR COBRAR" BgColor="bg-green-500" Icon="fas fas fa-dollar-sign" Valor={totalDolares} Titulo="Total de $ de facturas no pagadas" />
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="px-4 md:px-10 mx-auto w-full -m-24">
                    <div className="flex flex-wrap mt-4">
                        <div className="w-full mb-12 xl:mb-0 px-4">
                            <Container Titulo="Últimas 10 Facturas No Pagadas" mostrartitulo="true" boton="Ver todas" accionBoton={accionVerFacturas}>
                                <Table columnas={columnas} filas={dataFacturas} verRegistro={verRegistro}/>
                            </Container>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default Dashboard
