import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom"

import Ubicacion from "../../services/ubicacion";
import Cliente from '../../services/clientes'
import servicios from '../../services/servicios'

import Navbar from '../../components/Navbar'
import Sidebar from '../../components/Sidebar'
import Container from "../../components/UI/ContainerCard/Container";
import Footer from "../../components/UI/Footer";
import XsSpinner from '../../components/UI/Spinner/XsSpinner';
import Spinner from '../../components/UI/Spinner/Spinner'
import Facturacion from '../../components/Facturacion'

import { useToasts } from 'react-toast-notifications';
import InputMask from "react-input-mask";
import Select from 'react-select'
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/es';
import 'moment-timezone';

import { validarInput } from "../../helpers/validaciones";

import Modal from 'react-modal';

const Clientes = (props) => {
    const [clienteId, setClienteId] = useState(0)
    const [esActualizacion, setEsActualizacion] = useState(false)
    const [cargandoPantalla, setCargandoPantalla] = useState(true)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [modalFacturacionIsOpen, setModalFacturacionIsOpen] = useState(false)
    const [estaAsignando, setEstaAsignando] = useState(false)

    const [cl, setCl] = useState({
        numeroContrato: {
            placeholder: 'Número de Contrato',
            value: '',
            onlyNumbers: true,
            validation: {
                required: true,
                isNumeric: true,
                errorMsg: "",
            },
            valid: false,
            touched: false
        },
        tipoIdentificacion: {
            placeholder: null,
            value: 'F',
            validation: {
                required: false,
                errorMsg: "",
            },
            valid: true,
            touched: false
        },
        identificacion: {
            placeholder: '0-0000-0000',
            mask: '9-9999-9999',
            maxlength: '11',
            onlyNumbers: false,
            value: '',
            validation: {
                required: true,
                errorMsg: "",
                invalidClass: ''
            },
            valid: false,
            touched: false
        },
        nombre: {
            placeholder: 'Nombre',
            value: '',
            validation: {
                required: true,
                errorMsg: "",
                invalidClass: ''
            },
            valid: false,
            touched: false
        },
        primerApellido: {
            placeholder: 'Primer Apellido',
            value: '',
            validation: {
                required: true,
                errorMsg: "",
                invalidClass: ''
            },
            valid: false,
            touched: false
        },
        segundoApellido: {
            placeholder: 'Segundo Apellido',
            value: '',
            validation: {
                required: true,
                errorMsg: "",
                invalidClass: ''
            },
            valid: false,
            touched: false
        },
        telefono: {
            placeholder: '0000-0000',
            mask: '9999-9999',
            value: '',
            validation: {
                required: true,
                errorMsg: "",
                invalidClass: ''
            },
            valid: false,
            touched: false
        },
        celular: {
            placeholder: '0000-0000',
            mask: '9999-9999',
            value: '',
            validation: {
                required: false,
                errorMsg: ""
            },
            valid: true,
            touched: false
        },
        email: {
            placeholder: 'nombre@dominio.com',
            value: '',
            validation: {
                required: false,
                isEmail: true,
                errorMsg: ""
            },
            valid: true,
            touched: false
        },
        fax: {
            placeholder: '0000-0000',
            mask: '9999-9999',
            value: '',
            validation: {
                required: false,
                errorMsg: ""
            },
            valid: true,
            touched: false
        },
        provincia: {
            placeholder: null,
            value: '1',
            validation: {
                required: false,
                errorMsg: ""
            },
            valid: true,
            touched: false
        },
        canton: {
            placeholder: null,
            value: '1',
            validation: {
                required: false,
                errorMsg: ""
            },
            valid: true,
            touched: false
        },
        distrito: {
            placeholder: null,
            value: '1',
            validation: {
                required: false,
                errorMsg: ""
            },
            valid: true,
            touched: false
        },
        barrio: {
            placeholder: null,
            value: '1',
            validation: {
                required: false,
                errorMsg: ""
            },
            valid: true,
            touched: false
        },
        lugarDeTrabajo: {
            placeholder: 'Lugar de Trabajo',
            value: '',
            validation: {
                required: false,
                errorMsg: ""
            },
            valid: true,
            touched: false
        },
        direccionExacta: {
            placeholder: 'Dirección Exacta',
            value: '',
            validation: {
                required: false,
                errorMsg: ""
            },
            valid: true,
            touched: false
        },
        representanteLegal: {
            placeholder: 'Representante Legal',
            value: '',
            validation: {
                required: false,
                errorMsg: ""
            },
            valid: true,
            touched: false
        },
        fechaInstalacion: {
            placeholder: '',
            value: '',
            validation: {
                required: true,
                errorMsg: "",
                invalidClass: ''
            },
            valid: false,
            touched: false
        }
    })
    const [provincias, setProvincias] = useState([])
    const [cantones, setCantones] = useState([])
    const [distritos, setDistritos] = useState([])
    const [barrios, setBarrios] = useState([])
    const [esValido, setEsValido] = useState(false)
    const [estaCargando, setEstaCargando] = useState(false)
    const history = useHistory();

    const [serviciosRaw, setServiciosRaw] = useState([])
    const [serviciosData, setServiciosData] = useState([])
    const [serviciosCliente, setServiciosCliente] = useState([])


    const customStyles = {
        overlay: {
            'z-index': '9999999',
            'background-color': 'rgba(0, 0, 0, 0.4)'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)'
        }
    };

    const cambiarValor = (id, valor) => {
        const copiaCl = { ...cl }
        copiaCl[id].value = valor

        try {
            const validacion = validarInput(copiaCl[id].value, copiaCl[id].validation, id);
            copiaCl[id].valid = validacion.isValid;
            copiaCl[id].validation.errorMsg = validacion.isValid ? "" : validacion.msgError;
            copiaCl[id].validation.invalidClass = validacion.isValid ? '' : 'input-invalid'
        } catch (ex) {

        }


        let formEsValido = true;
        for (let inputIdentifier in copiaCl) {
            formEsValido = copiaCl[inputIdentifier].valid && formEsValido;
        }

        setEsValido(formEsValido);

        if (copiaCl[id].onlyNumbers) {
            const re = /^[0-9\b]+$/;
            if (valor === '' || re.test(valor)) {
                setCl({ ...copiaCl })
            }
        }
        else
            setCl({ ...copiaCl })
    }

    const cambiarPlaceholderTipoIdentificacion = (id, valor) => {
        const copiaCl = { ...cl }
        let placeholder = "";
        let maxlength = "0"
        let mask = '';

        if (valor === "F") {
            placeholder = "0-0000-0000";
            mask = "9-9999-9999";
            maxlength = "11";
        }
        else if (valor === "J") {
            placeholder = "0-000-000000";
            mask = "9-999-999999";
            maxlength = "12";
        }
        else if (valor === "D") {
            placeholder = "000000000000";
            mask = "999999999999";
            maxlength = "12";
        }
        else if (valor === "N") {
            placeholder = "0000000000";
            mask = "9999999999";
            maxlength = "10";
        }
        else if (valor === "E") {
            placeholder = "ABC000000000ABC";
            mask = "********************";
            maxlength = "20";
        }

        copiaCl['identificacion'].placeholder = placeholder;
        copiaCl['identificacion'].maxlength = maxlength;
        copiaCl['identificacion'].mask = mask;


        if (maxlength > 0)
            copiaCl['identificacion'].value = copiaCl['identificacion'].value.substring(0, maxlength);


        setCl({ ...copiaCl })
    }

    const handleChange = (e, id) => {
        cambiarValor(id, e.target.value)
        switch (id) {
            case 'provincia':
                obtenerCantones(e.target.value)
                break;
            case 'canton':
                obtenerDistritos()
                break;
            case 'distrito':
                obtenerBarrios()
                break;
            case 'tipoIdentificacion':
                cambiarPlaceholderTipoIdentificacion(id, e.target.value)
                break;
            default:
                break;
        }
    }

    const { addToast } = useToasts();

    const obtenerBarrios = () => {
        Ubicacion.obtenerBarrios(cl['provincia'].value, cl['canton'].value, cl['distrito'].value).then((resp) => {
            if (resp.status === 200) {
                setBarrios(resp.data)

                if (!esActualizacion)
                    setCargandoPantalla(false);
            } else
                addToast("Ha ocurrido un error al cargar barrios", { appearance: 'error', autoDismiss: true });
        })
    }

    const obtenerDistritos = () => {
        Ubicacion.obtenerDistritos(cl['provincia'].value, cl['canton'].value).then((resp) => {
            if (resp.status === 200) {
                setDistritos(resp.data);
                obtenerBarrios(1, 1, 1)
            }
            else
                addToast("Ha ocurrido un error al cargar distritos", { appearance: 'error', autoDismiss: true });
        })
    }

    const handleServiciosSeleccionados = (seleccion) => {
        if (seleccion.length > 1) {
            const servSeleccionado = seleccion[seleccion.length - 1];
            const servicioRawSeleccionado = serviciosRaw.filter((el) => {
                return el.idServicio === servSeleccionado.value
            });
            const servicioRawSeleccionadoInicial = serviciosRaw.filter((el) => {
                return el.idServicio === seleccion[0].value
            });
            console.log(servicioRawSeleccionado);
            console.log(servicioRawSeleccionadoInicial);
            if (servicioRawSeleccionado[0].moneda !== servicioRawSeleccionadoInicial[0].moneda) {
                addToast(`El servicio que seleccionaste esta en moneda ${servicioRawSeleccionado[0].moneda} cuando los servicios iniciales estan en moneda ${servicioRawSeleccionadoInicial[0].moneda}`, { appearance: 'error', autoDismiss: true });
                return;
            }
        }

        setServiciosCliente(seleccion);
    }

    const obtenerCantones = (provincia, canton) => {
        Ubicacion.obtenerCantones(provincia).then((resp) => {
            if (resp.status === 200) {
                setCantones(resp.data);
                if (cantones.length > 0) {
                    if (canton)
                        cambiarValor('canton', canton)
                    else
                        cambiarValor('canton', '1')
                }
                obtenerDistritos()
            }
            else
                addToast("Ha ocurrido un error al cargar cantones", { appearance: 'error', autoDismiss: true });
        });
    }

    const obtenerProvincias = () => {
        Ubicacion.obtenerProvincias().then((resp) => {
            if (resp.status === 200) {
                setProvincias(resp.data)
                obtenerCantones(1)
            }
            else
                addToast("Ha ocurrido un error al cargar provincias", { appearance: 'error', autoDismiss: true });
        })
    }

    const regresar = (e) => {
        e.preventDefault();
        history.push("/clientes")
    }

    const cargarCliente = () => {
        Cliente.obtenerClientePorId(props.match.params.id).then((resp) => {
            if (resp.data) {
                const c = resp.data;
                setClienteId(resp.data.idCliente);
                cambiarValor('numeroContrato', c.numeroContrato);
                cambiarValor('nombre', c.nombre);
                cambiarValor('primerApellido', c.apellido1);
                cambiarValor('segundoApellido', c.apellido2);
                cambiarValor('tipoIdentificacion', c.tipoIdentificacion);
                cambiarValor('identificacion', c.numeroIdentificacion);
                cambiarValor('representanteLegal', c.nombreRepresentante);
                cambiarValor('provincia', c.provincia);
                cambiarValor('canton', c.canton);
                cambiarValor('distrito', c.distrito);
                cambiarValor('barrio', c.barrio);
                cambiarValor('direccionExacta', c.direccion);
                cambiarValor('telefono', c.telefono);
                cambiarValor('celular', c.celular);
                cambiarValor('fax', c.fax);
                cambiarValor('lugarDeTrabajo', c.lugarDeTrabajo);
                cambiarValor('email', c.email);

                if (c.fechaInstalacion === "0001-01-01T00:00:00")
                    cambiarValor('fechaInstalacion', new moment("01/01/2014").format());
                else
                    cambiarValor('fechaInstalacion', c.fechaInstalacion);

                obtenerCantones(c.provincia, c.canton);
                obtenerServicios(resp.data.idCliente);

                setCargandoPantalla(false);
            }
        }).catch((error) => {
            console.log(error);
            addToast("Ha ocurrido un error al cargar cliente", { appearance: 'error', autoDismiss: true });
        });
    }

    const obtenerServicios = (id) => {
        servicios.obtener().then((resp) => {
            if (resp.data) {
                console.log(resp.data)
                setServiciosRaw(resp.data);
                const serviciosMaestro = resp.data.map(elem => (
                    {
                        value: elem.idServicio,
                        label: elem.descripcionCorta
                    }
                ));
                setServiciosData(serviciosMaestro);
                obtenerServiciosCliente(id, serviciosMaestro);
            }
        }).catch((error) => {
            let msg = "";
            if (error.error)
                msg = error.error;
            addToast(`Ha ocurrido un error al obtener servicios. ${msg}`, { appearance: 'error', autoDismiss: true });
        });
    };

    const asignarServicios = () => {
        const serviciosSeleccionados = [];
        serviciosCliente.forEach(element => serviciosSeleccionados.push(element.value));
        console.log(serviciosSeleccionados);
        if (serviciosSeleccionados) {
            setEstaAsignando(true)
            const payload = {
                clienteId: clienteId,
                servicios: serviciosSeleccionados
            };
            servicios.asignarServicios(payload).then((resp) => {
                addToast('Servicios asignados.', { appearance: 'success', autoDismiss: true });
                setEstaAsignando(false)
            }).catch((error) => {
                let msg = "";
                if (error.error)
                    msg = error.error;
                addToast(`Ha ocurrido un error al asignar servicios. ${msg}`, { appearance: 'error', autoDismiss: true });
                setEstaAsignando(false)
            });
        }
    }

    const obtenerServiciosCliente = (id, serviciosMaestro) => {
        if (id > 0) {
            servicios.obtenerPorClientes(id).then((resp) => {
                if (resp.data) {
                    console.log(resp.data);
                    let serviciosAsignados = [];
                    resp.data.forEach(el => {
                        let servEncontrado = serviciosMaestro.filter(function (x) {
                            console.log(x.value);
                            return x.value === el.servicioId
                        });
                        if (servEncontrado) {
                            serviciosAsignados.push(servEncontrado[0]);
                        }
                    })

                    setServiciosCliente(serviciosAsignados);
                    console.log(serviciosCliente);
                }
            }).catch((error) => {
                let msg = "";
                if (error.error)
                    msg = error.error;
                addToast(`Ha ocurrido un error al obtener servicios asignados. ${msg}`, { appearance: 'error', autoDismiss: true });
            });
        }
    }

    const closeModal = () => {
        setModalIsOpen(false);
    }

    const handleCerrarModalFacturacion = () => {
        setModalFacturacionIsOpen(false);
    }

    const submitCliente = (e) => {
        e.preventDefault();

        if (!esValido) {
            const copiaCl = { ...cl };
            for (let inputIdentifier in cl) {
                const validacion = validarInput(cl[inputIdentifier].value, cl[inputIdentifier].validation, inputIdentifier);
                copiaCl[inputIdentifier].valid = validacion.isValid;
                copiaCl[inputIdentifier].validation.errorMsg = validacion.isValid ? "" : validacion.msgError;
                copiaCl[inputIdentifier].validation.invalidClass = validacion.isValid ? '' : 'input-invalid'
            }
            setCl({ ...copiaCl });

            addToast('Debe verificar los campos requeridos para continuar.', { appearance: 'warning', autoDismiss: true });
            return;
        }
        setEstaCargando(true)

        //Guarda cliente
        const payload = {
            "nombre": cl.nombre.value,
            "apellido1": cl.primerApellido.value,
            "apellido2": cl.segundoApellido.value,
            "tipoIdentificacion": cl.tipoIdentificacion.value,
            "numeroIdentificacion": cl.identificacion.value,
            "nombreRepresentante": cl.representanteLegal.value,
            "provincia": cl.provincia.value,
            "canton": cl.canton.value,
            "distrito": cl.distrito.value,
            "barrio": cl.barrio.value,
            "direccion": cl.direccionExacta.value,
            "telefono": cl.telefono.value,
            "celular": cl.celular.value,
            "fax": cl.fax.value,
            "lugarDeTrabajo": cl.lugarDeTrabajo.value,
            "email": cl.email.value,
            "numeroContrato": cl.numeroContrato.value,
            "emailFactura": cl.email.value,
            "fechaInstalacion": cl.fechaInstalacion.value,
            "idCliente": clienteId
        }

        if (esActualizacion) {
            Cliente.actualizarCliente(payload).then((resp) => {
                addToast('Cliente actualizado.', { appearance: 'success', autoDismiss: true });
                setEstaCargando(false)
            }).catch((error) => {
                let msg = "";
                console.log(error.error)
                if (error.error)
                    msg = error.error;
                addToast(`Ha ocurrido un error al actualizar cliente. ${msg}`, { appearance: 'error', autoDismiss: true });
                setEstaCargando(false)
            })
        }
        else {
            Cliente.crearCliente(payload).then((resp) => {
                addToast('Cliente guardado.', { appearance: 'success', autoDismiss: true });
                setCl({
                    numeroContrato: {
                        placeholder: 'Número de Contrato',
                        value: '',
                        onlyNumbers: true,
                        validation: {
                            required: true,
                            isNumeric: true,
                            errorMsg: "",
                        },
                        valid: false,
                        touched: false
                    },
                    tipoIdentificacion: {
                        placeholder: null,
                        value: 'F',
                        validation: {
                            required: false,
                            errorMsg: "",
                        },
                        valid: true,
                        touched: false
                    },
                    identificacion: {
                        placeholder: '0-0000-0000',
                        mask: '9-9999-9999',
                        maxlength: '11',
                        onlyNumbers: false,
                        value: '',
                        validation: {
                            required: true,
                            errorMsg: "",
                            invalidClass: ''
                        },
                        valid: false,
                        touched: false
                    },
                    nombre: {
                        placeholder: 'Nombre',
                        value: '',
                        validation: {
                            required: true,
                            errorMsg: "",
                            invalidClass: ''
                        },
                        valid: false,
                        touched: false
                    },
                    primerApellido: {
                        placeholder: 'Primer Apellido',
                        value: '',
                        validation: {
                            required: true,
                            errorMsg: "",
                            invalidClass: ''
                        },
                        valid: false,
                        touched: false
                    },
                    segundoApellido: {
                        placeholder: 'Segundo Apellido',
                        value: '',
                        validation: {
                            required: true,
                            errorMsg: "",
                            invalidClass: ''
                        },
                        valid: false,
                        touched: false
                    },
                    telefono: {
                        placeholder: '0000-0000',
                        mask: '9999-9999',
                        value: '',
                        validation: {
                            required: true,
                            errorMsg: "",
                            invalidClass: ''
                        },
                        valid: false,
                        touched: false
                    },
                    celular: {
                        placeholder: '0000-0000',
                        mask: '9999-9999',
                        value: '',
                        validation: {
                            required: false,
                            errorMsg: ""
                        },
                        valid: true,
                        touched: false
                    },
                    email: {
                        placeholder: 'nombre@dominio.com',
                        value: '',
                        validation: {
                            required: false,
                            isEmail: true,
                            errorMsg: ""
                        },
                        valid: true,
                        touched: false
                    },
                    fax: {
                        placeholder: '0000-0000',
                        mask: '9999-9999',
                        value: '',
                        validation: {
                            required: false,
                            errorMsg: ""
                        },
                        valid: true,
                        touched: false
                    },
                    provincia: {
                        placeholder: null,
                        value: '1',
                        validation: {
                            required: false,
                            errorMsg: ""
                        },
                        valid: true,
                        touched: false
                    },
                    canton: {
                        placeholder: null,
                        value: '1',
                        validation: {
                            required: false,
                            errorMsg: ""
                        },
                        valid: true,
                        touched: false
                    },
                    distrito: {
                        placeholder: null,
                        value: '1',
                        validation: {
                            required: false,
                            errorMsg: ""
                        },
                        valid: true,
                        touched: false
                    },
                    barrio: {
                        placeholder: null,
                        value: '1',
                        validation: {
                            required: false,
                            errorMsg: ""
                        },
                        valid: true,
                        touched: false
                    },
                    lugarDeTrabajo: {
                        placeholder: 'Lugar de Trabajo',
                        value: '',
                        validation: {
                            required: false,
                            errorMsg: ""
                        },
                        valid: true,
                        touched: false
                    },
                    direccionExacta: {
                        placeholder: 'Dirección Exacta',
                        value: '',
                        validation: {
                            required: false,
                            errorMsg: ""
                        },
                        valid: true,
                        touched: false
                    },
                    representanteLegal: {
                        placeholder: 'Representante Legal',
                        value: '',
                        validation: {
                            required: false,
                            errorMsg: ""
                        },
                        valid: true,
                        touched: false
                    },
                    fechaInstalacion: {
                        placeholder: '',
                        value: '',
                        validation: {
                            required: true,
                            errorMsg: "",
                            invalidClass: ''
                        },
                        valid: false,
                        touched: false
                    }
                });
                setEstaCargando(false)
                history.push("/clientes")
            }).catch((error) => {
                let msg = "";
                console.log(error.error)
                if (error.error)
                    msg = error.error;
                addToast(`Ha ocurrido un error al guardar cliente. ${msg}`, { appearance: 'error', autoDismiss: true });
                setEstaCargando(false)
            })
        }
    }

    const abrirModalFacturacion = () => {
        console.log(cl.fechaInstalacion.value);
        if (cl.fechaInstalacion.value && cl.fechaInstalacion.value !== '' && cl.fechaInstalacion.value !== "0001-01-01T00:00:00" && cl.fechaInstalacion.value !== "2014-01-01T00:00:00-06:00") {
            setModalFacturacionIsOpen(true);
        } else {
            addToast(`Antes de facturar debe ingresar la fecha de instalación.`, { appearance: 'error', autoDismiss: true });
        }

    }

    useEffect(() => {
        const inicializar = () => {
            obtenerProvincias();

            if (props.match.params.id) {
                setEsActualizacion(true);
                cargarCliente();
            }
        }

        inicializar();
    }, [])

    return (
        <>
            <Sidebar />
            <div className="relative md:ml-52 bg-blueGray-100">
                <Navbar titulo="Mantenimiento de Clientes" />
                <div className="relative bg-blue-900 md:pt-20 pb-32 pt-12"></div>
                <div className="px-4 md:px-10 mx-auto w-full -m-24">
                    <div className="flex flex-wrap mt-4">
                        <div className="w-full mb-12 xl:mb-0 px-4">
                            <Container mostrartitulo="false">
                                {cargandoPantalla && <Spinner />}
                                {!cargandoPantalla &&
                                    <div>
                                        <form className="mt-6 mx-2" onSubmit={submitCliente}>
                                            <div className="grid md:grid-cols-6 gap-2 mt-7">
                                                <div className="mt-5 text-sm">
                                                    <label htmlFor="numeroContrato" className="block text-black">N° de Contrato</label>
                                                    <input type="text" id="numeroContrato" className={`rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full`}
                                                        disabled={esActualizacion ? 'disabled' : null}
                                                        placeholder={cl.numeroContrato.placeholder} value={cl.numeroContrato.value} onChange={(event) => handleChange(event, "numeroContrato")} />
                                                </div>
                                                {esActualizacion &&
                                                    <div className="mt-5 text-sm col-end-6">
                                                        <button className="btn transition bg-green-500 hover:bg-green-700" type="button" onClick={() => setModalIsOpen(true)}>Asignar Servicios</button>
                                                    </div>
                                                }
                                                {esActualizacion &&
                                                    <div className="mt-5 text-sm col-end-7">
                                                        <button className="btn transition bg-blue-500 hover:bg-blue-700" type="button" onClick={abrirModalFacturacion}>
                                                            <i className="fas fa-file-invoice-dollar mr-2"></i>
                                                            Facturar
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                            <div className="grid md:grid-cols-6 gap-2 mt-7">
                                                <div className="mt-5 text-sm">
                                                    <label htmlFor="tipoIdentificacion" className="block text-black">Tipo Identificación</label>
                                                    <select id="tipoIdentificacion" className="rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full"
                                                        value={cl.tipoIdentificacion.value} onChange={(event) => handleChange(event, "tipoIdentificacion")}>
                                                        <option value="F">Persona Física</option>
                                                        <option value="J">Persona Jurídica</option>
                                                        <option value="D">DIMEX</option>
                                                        <option value="N">NITE</option>
                                                        <option value="E">Extranjero</option>
                                                    </select>
                                                </div>
                                                <div className="mt-5 text-sm">
                                                    <label htmlFor="identificacion" className="block text-black">Identificación</label>
                                                    <InputMask mask={cl.identificacion.mask} id="identificacion" className={`rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full ${cl.identificacion.validation.invalidClass}`}
                                                        alwaysShowMask={false}
                                                        placeholder={cl.identificacion.placeholder} value={cl.identificacion.value} onChange={(event) => handleChange(event, "identificacion")} />
                                                </div>
                                                <div className="mt-5 text-sm col-span-2">
                                                    <label htmlFor="nombre" className="block text-black">Nombre</label>
                                                    <input type="text" id="nombre" className={`rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full ${cl.nombre.validation.invalidClass}`}
                                                        placeholder={cl.nombre.placeholder} value={cl.nombre.value} onChange={(event) => handleChange(event, "nombre")} />
                                                </div>
                                                <div className="mt-5 text-sm">
                                                    <label htmlFor="primerApellido" className="block text-black">Primer Apellido</label>
                                                    <input type="text" id="primerApellido" className={`rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full ${cl.primerApellido.validation.invalidClass}`}
                                                        placeholder={cl.primerApellido.placeholder} value={cl.primerApellido.value} onChange={(event) => handleChange(event, "primerApellido")} />
                                                </div>
                                                <div className="mt-5 text-sm">
                                                    <label htmlFor="segundoApellido" className="block text-black">Segundo Apellido</label>
                                                    <input type="text" id="segundoApellido" className={`rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full ${cl.segundoApellido.validation.invalidClass}`}
                                                        placeholder={cl.segundoApellido.placeholder} value={cl.segundoApellido.value} onChange={(event) => handleChange(event, "segundoApellido")} />
                                                </div>
                                            </div>
                                            <div className="grid md:grid-cols-6 gap-2">
                                                <div className="mt-5 text-sm">
                                                    <label htmlFor="telefono" className="block text-black">Teléfono</label>
                                                    <InputMask mask={cl.telefono.mask} id="telefono" className={`rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full ${cl.telefono.validation.invalidClass}`}
                                                        alwaysShowMask={false}
                                                        placeholder={cl.telefono.placeholder} value={cl.telefono.value} onChange={(event) => handleChange(event, "telefono")} />
                                                </div>
                                                <div className="mt-5 text-sm">
                                                    <label htmlFor="celular" className="block text-black">Celular</label>
                                                    <InputMask mask={cl.celular.mask} id="celular" className="rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full"
                                                        alwaysShowMask={false}
                                                        placeholder={cl.celular.placeholder} value={cl.celular.value} onChange={(event) => handleChange(event, "celular")} />
                                                </div>
                                                <div className="mt-5 text-sm col-span-2">
                                                    <label htmlFor="email" className="block text-black">Correo</label>
                                                    <input type="text" id="email" className={`rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full ${cl.email.validation.invalidClass}`}
                                                        placeholder={cl.email.placeholder} value={cl.email.value} onChange={(event) => handleChange(event, "email")} />
                                                    <small>{cl.email.validation.errorMsg}</small>
                                                </div>
                                                <div className="mt-5 text-sm">
                                                    <label htmlFor="fax" className="block text-black">Fax</label>
                                                    <InputMask mask={cl.fax.mask} id="fax" className="rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full"
                                                        alwaysShowMask={false}
                                                        placeholder={cl.fax.placeholder} value={cl.fax.value} onChange={(event) => handleChange(event, "fax")} />
                                                </div>
                                                <div className="mt-5 text-sm">
                                                    <label htmlFor="fax" className="block text-black">Fecha Instalación</label>
                                                    <Datetime closeOnSelect={true} initialValue={moment(new Date())} onChange={(date) => {
                                                        cambiarValor('fechaInstalacion', date.format())
                                                    }} id="fechaInstalacion" className={`clientes ${cl.fechaInstalacion.validation.invalidClass}`}
                                                        dateFormat="DD/MM/YYYY" timeFormat={false} locale="es" value={moment(cl.fechaInstalacion.value)} utc={false} />
                                                </div>
                                            </div>
                                            <div className="grid md:grid-cols-6 gap-2">
                                                <div className="mt-5 text-sm">
                                                    <label htmlFor="provincia" className="block text-black">Provincia</label>
                                                    <select id="provincia" className="rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full" value={cl.provincia.value}
                                                        onChange={(event) => handleChange(event, "provincia")}>
                                                        {provincias.map((data, id) => (
                                                            <option key={id} value={data.provincia}>{data.nombreProvincia}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="mt-5 text-sm">
                                                    <label htmlFor="canton" className="block text-black">Cantón</label>
                                                    <select id="canton" className="rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full" value={cl.canton.value}
                                                        onChange={(event) => handleChange(event, "canton")}>
                                                        {cantones.map((data, id) => (
                                                            <option key={id} value={data.canton}>{data.nombreCanton}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="mt-5 text-sm">
                                                    <label htmlFor="distrito" className="block text-black">Distrito</label>
                                                    <select id="distrito" className="rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full" value={cl.distrito.value}
                                                        onChange={(event) => handleChange(event, "distrito")}>
                                                        {distritos.map((data, id) => (
                                                            <option key={id} value={data.distrito}>{data.nombreDistrito}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="mt-5 text-sm">
                                                    <label htmlFor="barrio" className="block text-black">Barrio</label>
                                                    <select id="barrio" className="rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full" value={cl.barrio.value}
                                                        onChange={(event) => handleChange(event, "barrio")}>
                                                        {barrios.map((data, id) => (
                                                            <option key={id} value={data.barrio}>{data.nombreBarrio}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="mt-5 text-sm col-span-2">
                                                    <label htmlFor="lugarDeTrabajo" className="block text-black">Lugar de trabajo</label>
                                                    <input type="text" id="lugarDeTrabajo" className="rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full"
                                                        placeholder={cl.lugarDeTrabajo.placeholder} value={cl.lugarDeTrabajo.value} onChange={(event) => handleChange(event, "lugarDeTrabajo")} />
                                                </div>
                                            </div>
                                            <div className="grid md:grid-cols-1 gap-2">
                                                <div className="mt-5 text-sm">
                                                    <label htmlFor="direccionExacta" className="block text-black">Dirección</label>
                                                    <input type="text" id="direccionExacta" className="rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full"
                                                        placeholder={cl.direccionExacta.placeholder} value={cl.direccionExacta.value} onChange={(event) => handleChange(event, "direccionExacta")} />
                                                </div>
                                            </div>
                                            <div className="grid md:grid-cols-1 gap-2">
                                                <div className="mt-5 text-sm">
                                                    <label htmlFor="representanteLegal" className="block text-black">Nombre Representante Legal</label>
                                                    <input type="text" id="representanteLegal" className="rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full"
                                                        placeholder={cl.representanteLegal.placeholder} value={cl.representanteLegal.value} onChange={(event) => handleChange(event, "representanteLegal")} />
                                                </div>
                                            </div>
                                            {estaCargando && (
                                                <div className="grid md:grid-cols-1 gap-2">
                                                    <XsSpinner />
                                                </div>
                                            )}
                                            {!estaCargando && (
                                                <div className="grid md:grid-cols-2 gap-2 content-end">
                                                    <div className="m-5 text-sm">
                                                        <button onClick={regresar} className="block w-full px-6 py-2 text-xs font-medium leading-6 text-center text-white uppercase transition bg-blue-900 rounded shadow ripple hover:shadow-lg hover:bg-blue-800 focus:outline-none waves-effect" type="button">
                                                            Regresar
                                                        </button>
                                                    </div>
                                                    <div className="m-5 text-sm">
                                                        <button className="btn transition bg-green-500 hover:bg-green-700" type="submit">
                                                            {!esActualizacion && <span>Guardar</span>}
                                                            {esActualizacion && <span>Actualizar</span>}
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </form>
                                    </div>
                                }
                            </Container>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel=""
            >
                <div className="mt-2 mx-2 mb-2" style={{ height: "450px" }}>
                    <div className="grid md:grid-cols-3 gap-2">
                        <div className="mt-5 text-sm">
                            <label htmlFor="numeroContrato" className="block text-black">N° de Contrato</label>
                            <input type="text" id="numeroContrato" className={`rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full`}
                                disabled={esActualizacion ? 'disabled' : null}
                                placeholder={cl.numeroContrato.placeholder} value={cl.numeroContrato.value} onChange={(event) => handleChange(event, "numeroContrato")} />
                        </div>
                        <div className="mt-5 text-sm col-end-7">
                            <button type="button" onClick={() => setModalIsOpen(false)} className="btn transition bg-blue-500 hover:bg-blue-700 mt-8">X Cerrar</button>
                        </div>
                    </div>
                    <div className="mt-4">
                        <label className="block text-black">Servicios de Cliente:</label>
                        <small className="font-bold">* Tenga en consideración no mezclar servicios de diferente moneda.</small>
                        <Select
                            isMulti
                            closeMenuOnSelect={true}
                            value={serviciosCliente}
                            onChange={handleServiciosSeleccionados}
                            placeholder="Seleccione un servicio"
                            name="servicios"
                            noOptionsMessage={() => "No hay servicios"}
                            options={serviciosData}
                            className="basic-multi-select"
                            classNamePrefix="select"
                        />
                    </div>
                    <div className="mt-2">
                        <small>* Eliminar o Agregar servicios tendrá afectación directa en la siguiente facturación.</small>
                        {estaAsignando && (
                            <div className="grid md:grid-cols-1 gap-2">
                                <XsSpinner />
                            </div>
                        )}
                        {!estaAsignando && (
                            <button type="button" onClick={asignarServicios} className="btn transition bg-green-500">Guardar</button>
                        )}

                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={modalFacturacionIsOpen}
                onRequestClose={() => setModalFacturacionIsOpen(false)}
                style={customStyles}
                contentLabel=""
            >
                <Facturacion cliente={{
                    clienteId: clienteId,
                    numeroContrato: cl.numeroContrato.value,
                    nombre: cl.nombre.value,
                    primerApellido: cl.primerApellido.value,
                    segundoApellido: cl.segundoApellido.value
                }} serviciosCliente={serviciosCliente} CerrarModalFacturacion={handleCerrarModalFacturacion} />
            </Modal>
        </>
    )
}

export default Clientes
