import React, { useState } from 'react'
import { useHistory } from "react-router-dom"

import servicioPago from '../../services/pagos'
import cliente from '../../services/clientes'

import Container from "../../components/UI/ContainerCard/Container";
import Layout from '../../components/Layout'
import XsSpinner from '../../components/UI/Spinner/XsSpinner';

import { useToasts } from 'react-toast-notifications';
import InputMask from "react-input-mask";
import CurrencyFormat from 'react-currency-format';
import Datetime from 'react-datetime';

import moment from 'moment';
import 'moment/locale/es';

import { validarInput } from "../../helpers/validaciones";

import Modal from 'react-modal';

import TablaClientes from '../ListaClientes/components/TablaClientes'
import TablaFacturas from './components/TablaFacturas';

const customStyles = {
    overlay: {
        'z-index': '9999999',
        'background-color': 'rgba(0, 0, 0, 0.4)'
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

Modal.setAppElement('#root')

const Pago = (props) => {
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [modalFacturasIsOpen, setModalFacturasIsOpen] = useState(false)
    const [facturaNumero, setFacturaNumero] = useState(0)
    const [idCliente, setIdCliente] = useState(0)
    const [pago, setPago] = useState({
        numeroContrato: {
            placeholder: 'Número de Contrato',
            value: '',
            onlyNumbers: true,
            validation: {
                required: true,
                isNumeric: true,
                errorMsg: "",
            },
            valid: false,
            touched: false
        },
        tipoIdentificacion: {
            placeholder: null,
            value: 'F',
            validation: {
                required: false,
                errorMsg: "",
            },
            valid: true,
            touched: false
        },
        identificacion: {
            placeholder: '0-0000-0000',
            mask: '9-9999-9999',
            maxlength: '11',
            onlyNumbers: false,
            value: '',
            validation: {
                required: false,
                errorMsg: "",
                invalidClass: ''
            },
            valid: true,
            touched: false
        },
        nombre: {
            placeholder: 'Nombre',
            value: '',
            validation: {
                required: true,
                errorMsg: "",
                invalidClass: ''
            },
            valid: false,
            touched: false
        },
        primerApellido: {
            placeholder: 'Primer Apellido',
            value: '',
            validation: {
                required: true,
                errorMsg: "",
                invalidClass: ''
            },
            valid: false,
            touched: false
        },
        segundoApellido: {
            placeholder: 'Segundo Apellido',
            value: '',
            validation: {
                required: false,
                errorMsg: "",
                invalidClass: ''
            },
            valid: true,
            touched: false
        },
        tipoPago: {
            placeholder: '',
            value: '1',
            validation: {
                required: false,
                errorMsg: "",
                invalidClass: ''
            },
            valid: true,
            touched: false
        },
        referencia: {
            placeholder: 'Referencia de Pago',
            value: '',
            validation: {
                required: false,
                errorMsg: "",
                invalidClass: ''
            },
            valid: true,
            touched: false
        },
        descripcionPago: {
            placeholder: 'Descripción de Pago',
            value: '',
            validation: {
                required: true,
                errorMsg: "",
                invalidClass: ''
            },
            valid: false,
            touched: false
        },
        moneda: {
            placeholder: '',
            value: 'CRC',
            validation: {
                required: false,
                errorMsg: "",
                invalidClass: ''
            },
            valid: true,
            touched: false
        },
        totalPagado: {
            placeholder: '₡ 0.00',
            prefijo: '₡',
            value: '',
            validation: {
                required: true,
                errorMsg: "",
                isCurrency: true,
                invalidClass: ''
            },
            valid: false,
            touched: false
        },
        fechaHoraPago: {
            placeholder: 'dd/MM/yyyy',
            mask: '99/99/9999',
            value: '',
            validation: {
                required: true,
                errorMsg: "",
                invalidClass: ''
            },
            valid: false,
            touched: false
        },
        facturaCancela: {
            placeholder: '# Factura',
            mask: '999999999999999999999999999999',
            value: '',
            validation: {
                required: false,
                errorMsg: "",
                invalidClass: ''
            },
            valid: true,
            touched: false
        }
    })
    const [esValido, setEsValido] = useState(false)
    const [estaCargando, setEstaCargando] = useState(false)
    const [mensaje, setMensaje] = useState("")
    const history = useHistory();

    const cambiarValor = (id, valor) => {
        const copiaCl = { ...pago }
        copiaCl[id].value = valor

        try {
            const validacion = validarInput(copiaCl[id].value, copiaCl[id].validation, id);
            copiaCl[id].valid = validacion.isValid;
            copiaCl[id].validation.errorMsg = validacion.isValid ? "" : validacion.msgError;
            copiaCl[id].validation.invalidClass = validacion.isValid ? '' : 'input-invalid'
        } catch { console.log("Error al validar campo " + id) }


        let formEsValido = true;
        for (let inputIdentifier in copiaCl) {
            formEsValido = copiaCl[inputIdentifier].valid && formEsValido;
        }

        setEsValido(formEsValido);

        if (copiaCl[id].onlyNumbers) {
            const re = /^[0-9\b]+$/;
            if (valor === '' || re.test(valor)) {
                setPago({ ...copiaCl })
            }
        }
        else
            setPago({ ...copiaCl })
    }

    const openModal = () => {
        setModalIsOpen(true);
    }

    const openFacturacionModal = () => {
        setModalIsOpen(false);
        if (idCliente > 0)
            setModalFacturasIsOpen(true);
        else
            addToast(`Debe ingresar un cliente antes de buscar factura.`, { appearance: 'warning', autoDismiss: true });
    }

    const closeModal = () => {
        setModalIsOpen(false);
        setModalFacturasIsOpen(false);
    }

    const afterOpenModal = () => {

    }

    const seleccionar = (id) => {
        cliente.obtenerClientePorId(id).then((resp) => {
            setMensaje("Cargando cliente...")

            cambiarValor('numeroContrato', resp.data.numeroContrato);
            cambiarValor('nombre', resp.data.nombre);
            cambiarValor('primerApellido', resp.data.apellido1);
            cambiarValor('segundoApellido', resp.data.apellido2);
            cambiarValor('tipoIdentificacion', resp.data.tipoIdentificacion);
            cambiarValor('identificacion', resp.data.numeroIdentificacion);

            setIdCliente(resp.data.idCliente);
            setMensaje("")
        }).catch((error) => {
            setMensaje("")
            let msg = "";
            console.log(error)
            if (error.error)
                msg = error.error;
            addToast(`Ha ocurrido un error al obtener cliente por número de contrato. ${msg}`, { appearance: 'error', autoDismiss: true });
            setEstaCargando(false)
        })
        setModalIsOpen(false);
    }

    const seleccionarFactura = (id) => {
        if (id > 0) {
            setFacturaNumero(id);
            cambiarValor('facturaCancela', id);
        }
        setModalFacturasIsOpen(false);
    }

    const cambiarPlaceholderMoneda = (id, valor) => {
        const copiaCl = { ...pago }
        let placeholder = "";
        let prefijo = '';

        if (valor === "CRC") {
            placeholder = "₡ 0.00";
            prefijo = "₡";
        }
        else if (valor === "USD") {
            placeholder = "$ 0.00";
            prefijo = "$";
        }

        copiaCl['totalPagado'].placeholder = placeholder;
        copiaCl['totalPagado'].prefijo = prefijo;


        setPago({ ...copiaCl })
    }

    const cambiarPlaceholderTipoIdentificacion = (id, valor) => {
        const copiaCl = { ...pago }
        let placeholder = "";
        let maxlength = "0"
        let mask = '';

        if (valor === "F") {
            placeholder = "0-0000-0000";
            mask = "9-9999-9999";
            maxlength = "11";
        }
        else if (valor === "J") {
            placeholder = "0-000-000000";
            mask = "9-999-999999";
            maxlength = "12";
        }
        else if (valor === "D") {
            placeholder = "000000000000";
            mask = "999999999999";
            maxlength = "12";
        }
        else if (valor === "N") {
            placeholder = "0000000000";
            mask = "9999999999";
            maxlength = "10";
        }
        else if (valor === "E") {
            placeholder = "ABC000000000ABC";
            mask = "********************";
            maxlength = "20";
        }

        copiaCl['identificacion'].placeholder = placeholder;
        copiaCl['identificacion'].maxlength = maxlength;
        copiaCl['identificacion'].mask = mask;


        if (maxlength > 0)
            copiaCl['identificacion'].value = copiaCl['identificacion'].value.substring(0, maxlength);


        setPago({ ...copiaCl })
    }

    const obtenerClientePorContrato = (numeroContrato) => {
        if (numeroContrato && numeroContrato > 0) {
            setMensaje("Tratando de obtener cliente...")
            cliente.obtenerClientePorContrato(numeroContrato).then((resp) => {
                setMensaje("Cargando cliente...")
                cambiarValor('nombre', resp.data.nombre);
                cambiarValor('primerApellido', resp.data.apellido1);
                cambiarValor('segundoApellido', resp.data.apellido2);
                cambiarValor('tipoIdentificacion', resp.data.tipoIdentificacion);
                cambiarValor('identificacion', resp.data.numeroIdentificacion);
                setIdCliente(resp.data.idCliente);
                setMensaje("")
            }).catch((error) => {
                setMensaje("")
                let msg = "";
                console.log(error.error)
                if (error.error)
                    msg = error.error;
                addToast(`Ha ocurrido un error al obtener cliente por número de contrato. ${msg}`, { appearance: 'error', autoDismiss: true });
                setEstaCargando(false)
            })
        }
    }

    const handleChange = (e, id) => {
        cambiarValor(id, e.target.value)
        switch (id) {
            case 'tipoIdentificacion':
                cambiarPlaceholderTipoIdentificacion(id, e.target.value)
                break;
            case 'moneda':
                cambiarPlaceholderMoneda(id, e.target.value)
                break;
            default:
                break;
        }
    }

    const { addToast } = useToasts();

    const regresar = (e) => {
        e.preventDefault();
        history.push("/pagos")
    }

    const submitPago = (e) => {
        e.preventDefault();

        if (!esValido) {
            const copiaCl = { ...pago };
            for (let inputIdentifier in pago) {
                const validacion = validarInput(pago[inputIdentifier].value, pago[inputIdentifier].validation, inputIdentifier);
                copiaCl[inputIdentifier].valid = validacion.isValid;
                copiaCl[inputIdentifier].validation.errorMsg = validacion.isValid ? "" : validacion.msgError;
                copiaCl[inputIdentifier].validation.invalidClass = validacion.isValid ? '' : 'input-invalid'
            }
            setPago({ ...copiaCl });
            console.log(pago);
            addToast('Debe verificar los campos requeridos para continuar.', { appearance: 'warning', autoDismiss: true });
            return;
        }

        setEstaCargando(true)

        //Guarda cliente
        const payload = {
            "numeroContrato": pago.numeroContrato.value,
            "nombre": pago.nombre.value,
            "apellido1": pago.primerApellido.value,
            "apellido2": pago.segundoApellido.value,
            "tipoIdentificacion": pago.tipoIdentificacion.value,
            "numeroIdentificacion": pago.identificacion.value,
            "tipoPago": pago.tipoPago.value,
            "referencia": pago.referencia.value,
            "descripcionPago": pago.descripcionPago.value,
            "totalPagado": pago.totalPagado.value,
            "fechaHoraPago": pago.fechaHoraPago.value,
            "moneda": pago.moneda.value,
            "facturaCancela": pago.facturaCancela.value
        }

        servicioPago.registrarPago(payload).then((resp) => {
            addToast('Pago registrado.', { appearance: 'success', autoDismiss: true });
            setEstaCargando(false)
            setPago({
                numeroContrato: {
                    placeholder: 'Número de Contrato',
                    value: '',
                    onlyNumbers: true,
                    validation: {
                        required: true,
                        isNumeric: true,
                        errorMsg: "",
                    },
                    valid: false,
                    touched: false
                },
                tipoIdentificacion: {
                    placeholder: null,
                    value: 'F',
                    validation: {
                        required: false,
                        errorMsg: "",
                    },
                    valid: true,
                    touched: false
                },
                identificacion: {
                    placeholder: '0-0000-0000',
                    mask: '9-9999-9999',
                    maxlength: '11',
                    onlyNumbers: false,
                    value: '',
                    validation: {
                        required: false,
                        errorMsg: "",
                        invalidClass: ''
                    },
                    valid: true,
                    touched: false
                },
                nombre: {
                    placeholder: 'Nombre',
                    value: '',
                    validation: {
                        required: true,
                        errorMsg: "",
                        invalidClass: ''
                    },
                    valid: false,
                    touched: false
                },
                primerApellido: {
                    placeholder: 'Primer Apellido',
                    value: '',
                    validation: {
                        required: true,
                        errorMsg: "",
                        invalidClass: ''
                    },
                    valid: false,
                    touched: false
                },
                segundoApellido: {
                    placeholder: 'Segundo Apellido',
                    value: '',
                    validation: {
                        required: false,
                        errorMsg: "",
                        invalidClass: ''
                    },
                    valid: true,
                    touched: false
                },
                tipoPago: {
                    placeholder: '',
                    value: '1',
                    validation: {
                        required: false,
                        errorMsg: "",
                        invalidClass: ''
                    },
                    valid: true,
                    touched: false
                },
                referencia: {
                    placeholder: 'Referencia de Pago',
                    value: '',
                    validation: {
                        required: false,
                        errorMsg: "",
                        invalidClass: ''
                    },
                    valid: true,
                    touched: false
                },
                descripcionPago: {
                    placeholder: 'Descripción de Pago',
                    value: '',
                    validation: {
                        required: true,
                        errorMsg: "",
                        invalidClass: ''
                    },
                    valid: false,
                    touched: false
                },
                moneda: {
                    placeholder: '',
                    value: 'CRC',
                    validation: {
                        required: false,
                        errorMsg: "",
                        invalidClass: ''
                    },
                    valid: true,
                    touched: false
                },
                totalPagado: {
                    placeholder: '₡ 0.00',
                    prefijo: '₡',
                    value: '',
                    validation: {
                        required: true,
                        errorMsg: "",
                        isNumeric: true,
                        invalidClass: ''
                    },
                    valid: false,
                    touched: false
                },
                fechaHoraPago: {
                    placeholder: 'dd/MM/yyyy',
                    mask: '99/99/9999',
                    value: '',
                    validation: {
                        required: true,
                        errorMsg: "",
                        invalidClass: ''
                    },
                    valid: false,
                    touched: false
                },
                facturaCancela: {
                    placeholder: '# Factura',
                    mask: '999999999999999999999999999999',
                    value: '',
                    validation: {
                        required: false,
                        errorMsg: "",
                        invalidClass: ''
                    },
                    valid: true,
                    touched: false
                }
            })
        }).catch((error) => {
            let msg = "";
            console.log(error.error)
            if (error.error)
                msg = error.error;
            addToast(`Ha ocurrido un error al registrar pago. ${msg}`, { appearance: 'error', autoDismiss: true });
            setEstaCargando(false)
        })
    }

    return (
        <>
            <Layout titulo="Registrar Pago" subtitulo="Procesos &gt; Registrar Pago" >
                <Container mostrartitulo="false">
                    <div>
                        <span>{mensaje}</span>
                        <form className="mt-2 mx-2" onSubmit={submitPago}>
                            <div className="grid md:grid-cols-6 gap-2">
                                <div className="mt-5 text-sm">
                                    <label htmlFor="numeroContrato" className="block text-black">N° de Contrato</label>
                                    <input onBlur={(e) => obtenerClientePorContrato(e.target.value)} type="text" id="numeroContrato" className={`rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full ${pago.numeroContrato.validation.invalidClass}`}
                                        placeholder={pago.numeroContrato.placeholder} value={pago.numeroContrato.value} onChange={(event) => handleChange(event, "numeroContrato")} />
                                    <a className="underline text-blue-500" onClick={openModal} href="#">Buscar cliente</a>
                                </div>
                                <div className="mt-5 text-sm col-span-3">
                                    <label htmlFor="facturaCancela" className="block text-black">Factura a cancelar</label>
                                    <input id="facturaCancela" className={`rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full ${pago.facturaCancela.validation.invalidClass}`}
                                        placeholder={pago.facturaCancela.placeholder} value={pago.facturaCancela.value} onChange={(event) => handleChange(event, "facturaCancela")} />
                                    <a className="underline text-blue-500" onClick={openFacturacionModal} href="#">Buscar Factura</a>
                                </div>
                                <div className="mt-5 text-sm col-span-2 text-right pt-8 mr-2 border">
                                    <h2 className="text-3xl text-green-500 font-bold"><CurrencyFormat value={pago.totalPagado.value} thousandSeparator={true} prefix={pago.totalPagado.prefijo} displayType={'text'} /></h2>
                                </div>

                            </div>
                            <div className="grid md:grid-cols-6 gap-2 mt-7">
                                <div className="mt-5 text-sm">
                                    <label htmlFor="tipoIdentificacion" className="block text-black">Tipo Identificación</label>
                                    <select id="tipoIdentificacion" className="rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full"
                                        value={pago.tipoIdentificacion.value} onChange={(event) => handleChange(event, "tipoIdentificacion")}>
                                        <option value="F">Persona Física</option>
                                        <option value="J">Persona Jurídica</option>
                                        <option value="D">DIMEX</option>
                                        <option value="N">NITE</option>
                                        <option value="E">Extranjero</option>
                                    </select>
                                </div>
                                <div className="mt-5 text-sm">
                                    <label htmlFor="identificacion" className="block text-black">Identificación</label>
                                    <InputMask mask={pago.identificacion.mask} id="identificacion" className={`rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full ${pago.identificacion.validation.invalidClass}`}
                                        alwaysShowMask={false}
                                        placeholder={pago.identificacion.placeholder} value={pago.identificacion.value} onChange={(event) => handleChange(event, "identificacion")} />
                                </div>
                                <div className="mt-5 text-sm col-span-2">
                                    <label htmlFor="nombre" className="block text-black">Nombre</label>
                                    <input type="text" id="nombre" className={`rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full ${pago.nombre.validation.invalidClass}`}
                                        placeholder={pago.nombre.placeholder} value={pago.nombre.value} onChange={(event) => handleChange(event, "nombre")} />
                                </div>
                                <div className="mt-5 text-sm">
                                    <label htmlFor="primerApellido" className="block text-black">Primer Apellido</label>
                                    <input type="text" id="primerApellido" className={`rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full ${pago.primerApellido.validation.invalidClass}`}
                                        placeholder={pago.primerApellido.placeholder} value={pago.primerApellido.value} onChange={(event) => handleChange(event, "primerApellido")} />
                                </div>
                                <div className="mt-5 text-sm">
                                    <label htmlFor="segundoApellido" className="block text-black">Segundo Apellido</label>
                                    <input type="text" id="segundoApellido" className={`rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full ${pago.segundoApellido.validation.invalidClass}`}
                                        placeholder={pago.segundoApellido.placeholder} value={pago.segundoApellido.value} onChange={(event) => handleChange(event, "segundoApellido")} />
                                </div>
                            </div>
                            <div className="grid md:grid-cols-6 gap-2 mt-7">
                                <div className="mt-5 text-sm">
                                    <label htmlFor="moneda" className="block text-black">Moneda</label>
                                    <select id="moneda" className="rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full"
                                        value={pago.moneda.value} onChange={(event) => handleChange(event, "moneda")}>
                                        <option value="CRC">₡ - Colones</option>
                                        <option value="USD">$ - Dolares</option>
                                    </select>
                                </div>
                                <div className="mt-5 text-sm">
                                    <label htmlFor="tipoPago" className="block text-black">Tipo de Pago</label>
                                    <select id="tipoPago" className="rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full"
                                        value={pago.tipoPago.value} onChange={(event) => handleChange(event, "tipoPago")}>
                                        <option value="1">Transferencia</option>
                                        <option value="2">Cheque</option>
                                        <option value="3">Efectivo</option>
                                        <option value="4">Tarjeta</option>
                                    </select>
                                </div>
                                <div className="mt-5 text-sm col-span-2">
                                    <label htmlFor="referencia" className="block text-black">Documento de Referencia</label>
                                    <input type="text" id="referencia" className={`rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full ${pago.referencia.validation.invalidClass}`}
                                        placeholder={pago.referencia.placeholder} value={pago.referencia.value} onChange={(event) => handleChange(event, "referencia")} />
                                </div>
                                <div className="mt-5 text-sm col-span-2">
                                    <label htmlFor="totalPagado" className="block text-black">Total Pagado:</label>
                                    <CurrencyFormat id="totalPagado" value={pago.totalPagado.value} thousandSeparator={true} prefix={pago.totalPagado.prefijo} placeholder={pago.totalPagado.placeholder}
                                        className={`rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full`} onValueChange={(values) => {
                                            const { formattedValue, value } = values;
                                            cambiarValor("totalPagado", value)
                                        }} />
                                </div>
                            </div>
                            <div className="grid md:grid-cols-6 gap-2 mt-7">
                                <div className="mt-5 text-sm">
                                    <label htmlFor="fechaHoraPago" className="block text-black">Fecha de Pago</label>
                                    <Datetime id="fechaHoraPago" initialValue={new Date()} className={`${pago.fechaHoraPago.validation.invalidClass}`} dateFormat="DD/MM/YYYY" timeFormat={false} locale="es"
                                        value={moment(pago.fechaHoraPago.value)} utc={true} onChange={(date) => {
                                            cambiarValor('fechaHoraPago', date.format())
                                        }} />
                                </div>
                                <div className="mt-5 text-sm col-span-5">
                                    <label htmlFor="descripcionPago" className="block text-black">Descripción de Pago</label>
                                    <input type="text" id="descripcionPago" className={`rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full ${pago.descripcionPago.validation.invalidClass}`}
                                        placeholder={pago.descripcionPago.placeholder} value={pago.descripcionPago.value} onChange={(event) => handleChange(event, "descripcionPago")} />
                                </div>
                            </div>
                            {estaCargando && (
                                <div className="grid md:grid-cols-1 gap-2">
                                    <XsSpinner />
                                </div>
                            )}
                            {!estaCargando && (
                                <div className="grid md:grid-cols-2 gap-2 content-end">
                                    <div className="m-5 text-sm">
                                        <button onClick={regresar} className="block w-full px-6 py-2 text-xs font-medium leading-6 text-center text-white uppercase transition bg-blue-900 rounded shadow ripple hover:shadow-lg hover:bg-blue-800 focus:outline-none waves-effect" type="button">
                                            Regresar
                                        </button>
                                    </div>
                                    <div className="m-5 text-sm">
                                        <button className="btn transition bg-green-500 hover:bg-green-700" type="submit">
                                            <span>Registrar Pago</span>
                                        </button>
                                    </div>
                                </div>
                            )}
                        </form>
                    </div>
                </Container>
            </Layout>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel=""
            >
                <TablaClientes esModal={true} onClose={closeModal} seleccionar={seleccionar} />
            </Modal>
            <Modal
                isOpen={modalFacturasIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel=""
            >
                <TablaFacturas esModal={true} idCliente={idCliente} onClose={closeModal} seleccionar={seleccionarFactura} />
            </Modal>
        </>
    )
}

export default Pago
