import React, { useState } from 'react'


import Layout from '../../components/Layout'
import Container from "../../components/UI/ContainerCard/Container";
import TablaClientes from './components/TablaClientes'



const ListaClientes = () => {
    return (
        <>
            <Layout titulo="Lista de Clientes" subtitulo="Lista &gt; Clientes" >
                <Container mostrartitulo="false">
                    <TablaClientes />
                </Container>
            </Layout>
        </>
    )
}

export default ListaClientes
