import React, { useState, useEffect } from 'react'

import CurrencyFormat from 'react-currency-format';
import { useToasts } from 'react-toast-notifications';
import XsSpinner from '../../components/UI/Spinner/XsSpinner';

import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/es';
import 'moment-timezone';


//servicios
import cliente from '../../services/clientes'

const Facturacion = (props) => {
    const { addToast } = useToasts();

    //data
    const [facturaTemp, setFacturaTemp] = useState([]);
    const [facturaTempDetalle, setFacturaTempDetalle] = useState([]);
    const [cargandoData, setCargandoData] = useState(true)
    const [Annos, setAnnos] = useState([]);
    const [Moneda, setMoneda] = useState("₡")
    const [estaFacturando, setEstaFacturando] = useState(false)
    const [ExisteFactura, setExisteFactura] = useState(false)
    const [FechaVencimiento, setFechaVencimiento] = useState()

    //Estados
    const [mesAFacturar, setMesAFacturar] = useState(1)
    const [annoAFacturar, setAnnoAFacturar] = useState()


    //Inicializar
    useEffect(() => {
        const inicializar = () => {
            var day = moment().format('D');
            console.log(day);
            let fecVencimiento;
            if (day > 10) {
                fecVencimiento = moment().add(1, 'M');
                fecVencimiento = fecVencimiento.set('date', 10);
                setFechaVencimiento(fecVencimiento);
            }
            else {
                fecVencimiento = moment().set('date', 10);
                setFechaVencimiento(fecVencimiento);
            }
            const d = new Date();
            setMesAFacturar(d.getMonth() + 1);
            setAnnoAFacturar(d.getFullYear());
            let annos = [];
            annos.push(d.getFullYear() - 1);
            annos.push(d.getFullYear());
            annos.push(d.getFullYear() + 1);
            setAnnos(annos);
            if (props.serviciosCliente.length > 0) {
                cliente.obtenerFacturaTemporal(props.cliente.clienteId, d.getMonth() + 1, d.getFullYear()).then((resp) => {
                    if (resp.data) {
                        const c = resp.data;
                        if (c) {
                            setMoneda(c.detalle[0].monedaSimbolo);
                        }

                        setExisteFactura(c.yaExiste);
                        setFacturaTemp(c);
                        setFacturaTempDetalle(c.detalle);
                        setCargandoData(false)
                    }
                }).catch((error) => {
                    console.log(error);
                    setCargandoData(false)
                    addToast("Ha ocurrido un error al cargar cliente", { appearance: 'error', autoDismiss: true });
                });
            }
        }

        inicializar();
    }, [])
    const ObtenerFacturaTemporal = (mes, anno) => {
        setExisteFactura(false);
        setCargandoData(true);
        cliente.obtenerFacturaTemporal(props.cliente.clienteId, mes, anno).then((resp) => {
            if (resp.data) {
                const c = resp.data;
                if (c) {
                    setMoneda(c.detalle[0].monedaSimbolo);
                }
                setExisteFactura(c.yaExiste);
                setFacturaTemp(c);
                setFacturaTempDetalle(c.detalle);
                setCargandoData(false)
            }
        }).catch((error) => {
            console.log(error);
            setCargandoData(false)
            addToast("Ha ocurrido un error al cargar cliente", { appearance: 'error', autoDismiss: true });
        });
    }
    //Funciones
    const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }
    const facturar = () => {
        setEstaFacturando(true);
        console.log("Mes a Factura", mesAFacturar);
        cliente.facturar(props.cliente.clienteId, mesAFacturar, annoAFacturar, FechaVencimiento.format()).then((resp) => {
            window.open(`https://services.ticolineacr.com:28800/api/Cliente/DescargarFactura?clienteId=${props.cliente.clienteId}&mes=${mesAFacturar}&anno=${annoAFacturar}`,"_blank");
            /*cliente.descargarFactura(props.cliente.clienteId, mesAFacturar, annoAFacturar).then((resp) => {
                if (resp.data) {
                    const contentType = 'application/pdf';

                    const blob = b64toBlob(resp.data, contentType);
                    const blobUrl = URL.createObjectURL(blob);

                    const img = document.createElement('img');
                    img.src = blobUrl;
                    document.body.appendChild(img);

                    const file = blob;
                    const fileURL = URL.createObjectURL(file);
                    const link = document.createElement('a');
                    link.href = fileURL;
                    var dt = new Date();
                    link.download = "Factura_" + props.cliente.nombre + "_" + props.cliente.primerApellido + dt.getFullYear() + (dt.getMonth() + 1) + ".pdf";
                    link.click();
                    setEstaFacturando(false);
                    props.CerrarModalFacturacion();
                    addToast("Facturación completada", { appearance: 'success', autoDismiss: true });
                }
            });*/
        }).catch((error) => {
            console.log(error);
            addToast("Ha ocurrido un error al facturar.", { appearance: 'error', autoDismiss: true });
            setEstaFacturando(false);
        });
    }
    return (
        <>
            <div className="mt-2 mx-2 mb-2" style={{ height: "600px" }}>
                <div className="grid md:grid-cols-6 gap-2">
                    <div className="mt-5 text-sm">
                        <label className="block text-black font-semibold">N° de Contrato:</label>
                        <label className="block text-black">{props.cliente.numeroContrato}</label>
                    </div>
                    <div className="mt-5 text-sm">
                        <label className="block text-black font-semibold">Nombre:</label>
                        <label className="block text-black">{props.cliente.nombre} {props.cliente.primerApellido} {props.cliente.segundoApellido}</label>
                    </div>
                </div>
                {props.serviciosCliente.length > 0 && (
                    <div>
                        <div className="grid md:grid-cols-6 gap-2">
                            <div className="mt-5 text-sm pt-5">
                                <label className="block text-black font-semibold">Mes Factura:</label>
                            </div>
                            <div className="mt-5 text-sm align-middle">
                                <select className="rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full"
                                    value={mesAFacturar} onChange={e => { setMesAFacturar(e.target.value); ObtenerFacturaTemporal(e.target.value, annoAFacturar) }}>
                                    <option value="1">Enero</option>
                                    <option value="2">Febrero</option>
                                    <option value="3">Marzo</option>
                                    <option value="4">Abril</option>
                                    <option value="5">Mayo</option>
                                    <option value="6">Junio</option>
                                    <option value="7">Julio</option>
                                    <option value="8">Agosto</option>
                                    <option value="9">Septiembre</option>
                                    <option value="10">Octubre</option>
                                    <option value="11">Noviembre</option>
                                    <option value="12">Diciembre</option>
                                </select>
                            </div>
                            <div className="mt-5 text-sm align-middle">
                                <select className="rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full"
                                    value={annoAFacturar} onChange={e => setAnnoAFacturar(e.target.value)}>
                                    {Annos.map((anno) =>
                                        <option value={anno}>{anno}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="grid md:grid-cols-6 gap-2">
                            <div className="mt-5 text-sm pt-5">
                                <label className="block text-black font-semibold">Fecha Vencimiento:</label>
                            </div>
                            <div className="mt-5 text-sm align-middle">
                                <Datetime className='clientes' closeOnSelect={true} initialValue={moment(new Date())} onChange={(date) => {
                                    setFechaVencimiento(date)
                                }} id="fechaVencimiento" dateFormat="DD/MM/YYYY" timeFormat={false} locale="es" value={FechaVencimiento} utc={false} />
                            </div>
                        </div>
                        <br></br>
                        <hr></hr>
                        {cargandoData &&
                            <div className="grid md:grid-cols-1 gap-2">
                                <XsSpinner />
                                <h1 className="text-center">Obteniendo factura...</h1>
                            </div>
                        }
                        {ExisteFactura &&
                            <small className='font-bold'>* La factura para el mes y año seleccionado ya se encuentra procesada.</small>
                        }
                        {!cargandoData &&
                            <div className="mt-4">
                                <table class="text-left w-full border-collapse text-sm">
                                    <thead>
                                        <tr>
                                            <th class="py-2 px-6 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light">Producto/Servicio</th>
                                            <th class="py-2 px-6 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light">Monto</th>
                                            <th class="py-2 px-6 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light">Imp IVA</th>
                                            <th class="py-2 px-6 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light">Imp 911</th>
                                            <th class="py-2 px-6 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light">Imp Cruz Roja</th>
                                            <th class="py-2 px-6 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light  text-right">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {facturaTempDetalle.map((serv) =>
                                            <tr class="hover:bg-grey-lighter">
                                                <td class="py-2 px-6 border-b border-grey-light">{serv.descripcion}</td>
                                                <td class="py-2 px-6 border-b border-grey-light text-right"><CurrencyFormat fixedDecimalScale={true} decimalScale={0} decimalSeparator="" value={serv.monto} thousandSeparator={true} prefix={Moneda} displayType={'text'} /></td>
                                                <td class="py-2 px-6 border-b border-grey-light text-right"><CurrencyFormat fixedDecimalScale={true} decimalScale={0} decimalSeparator="" value={serv.montoImpuesto} thousandSeparator={true} prefix={Moneda} displayType={'text'} /></td>
                                                <td class="py-2 px-6 border-b border-grey-light text-right"><CurrencyFormat fixedDecimalScale={true} decimalScale={0} decimalSeparator="" value={serv.impuesto911} thousandSeparator={true} prefix={Moneda} displayType={'text'} /></td>
                                                <td class="py-2 px-6 border-b border-grey-light text-right"><CurrencyFormat fixedDecimalScale={true} decimalScale={0} decimalSeparator="" value={serv.impuestoCruzRoja} thousandSeparator={true} prefix={Moneda} displayType={'text'} /></td>
                                                <td class="py-2 px-6 border-b border-grey-light text-right"><CurrencyFormat fixedDecimalScale={true} decimalScale={0} decimalSeparator="" value={serv.total} thousandSeparator={true} prefix={Moneda} displayType={'text'} /></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                <br></br>
                                <hr></hr>
                                <div className="grid md:grid-cols-2 gap-2">
                                    <div className="mt-3 text-sm">
                                        <label className="block text-black font-semibold ml-2">Subtotal</label>
                                    </div>
                                    <div className="mt-3 text-sm">
                                        <label className="block text-black font-semibold ml-12 mr-3 text-right">
                                            <CurrencyFormat value={facturaTemp.subtotal} fixedDecimalScale={true} decimalScale={0} decimalSeparator="" thousandSeparator={true} prefix={Moneda} displayType={'text'} /></label>
                                    </div>
                                </div>
                                <div className="grid md:grid-cols-2 gap-2">
                                    <div className="mt-3 text-sm">
                                        <label className="block text-black font-semibold ml-2">Impuesto 911</label>
                                    </div>
                                    <div className="mt-3 text-sm">
                                        <label className="block text-black font-semibold ml-12 mr-3 text-right">
                                            <CurrencyFormat value={facturaTemp.totalImpuesto911} fixedDecimalScale={true} decimalScale={0} decimalSeparator="" thousandSeparator={true} prefix={Moneda} displayType={'text'} /></label>
                                    </div>
                                </div>
                                <div className="grid md:grid-cols-2 gap-2">
                                    <div className="mt-3 text-sm">
                                        <label className="block text-black font-semibold ml-2">Impuesto Cruz Roja</label>
                                    </div>
                                    <div className="mt-3 text-sm">
                                        <label className="block text-black font-semibold ml-12 mr-3 text-right">
                                            <CurrencyFormat value={facturaTemp.totalImpuestoCruzRoja} fixedDecimalScale={true} decimalScale={0} decimalSeparator="" thousandSeparator={true} prefix={Moneda} displayType={'text'} /></label>
                                    </div>
                                </div>
                                <div className="grid md:grid-cols-2 gap-2">
                                    <div className="mt-3 text-sm">
                                        <label className="block text-black font-semibold ml-2">Impuesto IVA</label>
                                    </div>
                                    <div className="mt-3 text-sm">
                                        <label className="block text-black font-semibold ml-12 mr-3 text-right">
                                            <CurrencyFormat value={facturaTemp.totalImpuestoVenta} fixedDecimalScale={true} decimalScale={0} decimalSeparator="" thousandSeparator={true} prefix={Moneda} displayType={'text'} /></label>
                                    </div>
                                </div>
                                <div className="grid md:grid-cols-2 gap-2">
                                    <div className="mt-1 text-sm">
                                        <label className="block text-black font-semibold text-lg ml-2">Total</label>
                                    </div>
                                    <div className="mt-1 text-sm">
                                        <label className="block text-black font-semibold text-lg ml-12 text-right mr-3"><CurrencyFormat value={facturaTemp.total} fixedDecimalScale={true} decimalScale={0} decimalSeparator="" thousandSeparator={true} prefix={Moneda} displayType={'text'} /></label>
                                    </div>
                                </div>
                            </div>
                        }
                        <hr></hr>
                        <br></br>
                        {estaFacturando && (
                            <div className="grid md:grid-cols-1 gap-2">
                                <small className="text-center">Generando Factura...</small>
                                <XsSpinner />
                            </div>
                        )}
                        {!estaFacturando && (
                            <div className="mt-2">
                                {!cargandoData &&
                                    <button type="button" onClick={facturar} className="btn bg-blue-500">{ExisteFactura ? 'Descargar Factura Existente' : 'Facturar'}</button>
                                }
                                <button type="button" className="btn bg-gray-500 mt-4" onClick={props.CerrarModalFacturacion}><i className="far fa-times-circle"></i> Cerrar</button>
                            </div>
                        )}

                    </div>
                )}
                {props.serviciosCliente.length === 0 && (
                    <div className="mt-12 text-lg font-semibold">
                        <h2>¡Debe asignar servicios al cliente para facturar!</h2>
                        <button type="button" className="btn bg-gray-500 mt-4" onClick={props.CerrarModalFacturacion}><i className="far fa-times-circle"></i> Cerrar</button>
                    </div>

                )}
            </div>
        </>
    )
}

export default Facturacion
