import React, { useState } from 'react'
import { Link } from "react-router-dom";

function Sidebar() {
    const [collapseShow, setCollapseShow] = useState("hidden");

    return (
        <>
            <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-gray-200 flex flex-wrap items-center justify-between relative md:w-52 z-10 py-4 px-3">
                <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
                    <button
                        className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                        type="button"
                        onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
                    >
                        <i className="fas fa-bars"></i>
                    </button>
                    <Link className="md:block text-center text-blue-900 md:pb-2 mr-0 inline-block whitespace-nowrap text-sm font-bold p-4 px-0 uppercase border-b-2 border-fuchsia-600" to="/">
                        Ticolinea Facturación
                    </Link>
                    <ul className="md:hidden items-center flex flex-wrap list-none">
                        <li className="inline-block relative">

                        </li>
                        <li className="inline-block relative">

                        </li>
                    </ul>
                    <div
                        className={
                            "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
                            collapseShow
                        }>
                        <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
                            <div className="flex flex-wrap">
                                <div className="w-6/12">
                                    <Link
                                        className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                                        to="/">
                                        Ticolinea App
                                    </Link>
                                </div>
                                <div className="w-6/12 flex justify-end">
                                    <button
                                        type="button"
                                        className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                                        onClick={() => setCollapseShow("hidden")}>
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <form className="mt-6 mb-4 md:hidden">
                            <div className="mb-3 pt-0">
                                <input
                                    type="text"
                                    placeholder="Search"
                                    className="border-0 px-3 py-2 h-12 border border-solid  border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                                />
                            </div>
                        </form>
                        <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                            <li className="items-center">
                                <Link className="text-blueGray-700 hover:text-blueGray-500 text-sm block mb-4 no-underline font-semibold text-blue-500" to="/">
                                    <i className="fas fa-chart-line text-blueGray-400 mr-2 text-base"></i>  &nbsp;&nbsp;Dashboard
                                </Link>
                            </li>
                            <li className="items-center">
                                <Link className="text-blueGray-700 hover:text-blueGray-500 text-sm block mb-4 no-underline font-semibold" to="/clientes">
                                    <i className="fas fa-file-invoice-dollar text-blueGray-400 mr-2 text-base"></i>  &nbsp;&nbsp;Facturación
                                </Link>
                            </li>
                            <li className="items-center">
                                <Link className="text-blueGray-700 hover:text-blueGray-500 text-sm block mb-4 no-underline font-semibold" to="/pago">
                                    <i className="fas fa-money-check-alt text-blueGray-400 mr-2 text-base"></i> Registrar Pago
                                </Link>
                            </li>
                            <li className="items-center">
                                <Link className="text-blueGray-700 hover:text-blueGray-500 text-sm block mb-4 no-underline font-semibold" to="/clientes">
                                    <i className="fas fa-user-friends text-blueGray-400 mr-2 text-base"></i> Clientes
                                </Link>
                            </li>
                        </ul>
                        <hr className="my-4 md:min-w-full" />
                        <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                            Reportes
                        </h6>
                        <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
                            <li className="inline-flex">
                                <Link className="text-blueGray-700 hover:text-blueGray-500 text-sm block mb-4 no-underline font-semibold" to="/listafacturas">
                                    <i className="fas fa-file-pdf mr-2 text-blueGray-400 text-base"></i> Lista Facturas
                                </Link>
                            </li>
                            <li className="inline-flex">
                                <Link className="text-blueGray-700 hover:text-blueGray-500 text-sm block mb-4 no-underline font-semibold" to="/pagos">
                                    <i className="fas fa-file-pdf mr-2 text-blueGray-400 text-base"></i> Pagos Registrados
                                </Link>
                            </li>
                            <li className="inline-flex">
                                <Link className="text-blueGray-700 hover:text-blueGray-500 text-sm block mb-4 no-underline font-semibold" to="/pagosExternos">
                                    <i className="fas fa-file-pdf mr-2 text-blueGray-400 text-base"></i> Pagos de Clientes
                                </Link>
                            </li>
                        </ul>
                        <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                            Mantenimientos
                        </h6>
                        <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
                            <li className="inline-flex">
                                <Link className="text-blueGray-700 hover:text-blueGray-500 text-sm block mb-4 no-underline font-semibold" to="/servicios" >
                                    <i className="fas fa-people-carry mr-2 text-blueGray-400 text-base"></i> Servicios
                                </Link>
                            </li>
                            <li className="inline-flex">
                                <Link className="text-blueGray-700 hover:text-blueGray-500 text-sm block mb-4 no-underline font-semibold" to="/cliente" >
                                    <i className="fas fa-user-check mr-2 text-blueGray-400 text-base"></i> Nuevo Cliente
                                </Link>
                            </li>
                            <li className="inline-flex">
                                <Link className="text-blueGray-700 hover:text-blueGray-500 text-sm block mb-4 no-underline font-semibold" to="/" >
                                    <i className="fas fa-server mr-2 text-blueGray-400 text-base"></i> Equipos
                                </Link>
                            </li>
                            <li className="inline-flex">
                                <Link className="text-blueGray-700 hover:text-blueGray-500 text-sm block mb-4 no-underline font-semibold" to="/">
                                    <i className="fas fa-file-invoice mr-2 text-blueGray-400 text-base"></i> Facturación Recurrente
                                </Link>
                            </li>
                            <li className="inline-flex">
                                <Link className="text-blueGray-700 hover:text-blueGray-500 text-sm block mb-4 no-underline font-semibold" to="/">
                                    <i className="fa fa-building mr-2 text-blueGray-400 text-base"></i> Mi Empresa
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Sidebar
