import http from '../axiosExterno'

/*
{
  "idReporte": 0,
  "numeroContrato": 500,
  "nombreCompleto": "Allan Salgado",
  "referencia": "55555",
  "email": "allan.salgado3@gmail.com",
  "moneda": "USD",
  "totalPagado": 150000,
  "fechaQueReporta": "2021-05-15T20:05:02.170Z",
  "fechaCreacion": "2021-05-15T20:05:02.170Z",
  "comentarios": "comentarios de prueba",
  "urlImagen": "https://localhost:44378/swagger/index.html",
  "estado": "string",
  "captchaToken": "03AGdBq26PJyDCjftF9x9z-lxo2NjchC8M6RAmBi5PfB0EK8ismXJV0_Bn02zIqr7TKLmY2zMY06mI_N9yKyFgP5BoWGPd1kz4tWJFfzM9EzuKH-TMxZ_6gGGfE0Nohl_h_oY7iZ6MBQ098ZBzeb0m0GEwxRbKFrDwutk97zAvJ9uFULxhat3d0CFsIWS4Xmwx5WSTVO-5vBIjGkj6D0YezioWr3GLMKECqofOK6WbFX06uHS62xktS1OF9nYfMfnCw76ItPebqvQ-DmnoO9vWE8wf1N4gz7dQrQCio4hMMU_x3N8XKMoiYARBG3V295tfud7T4_KVt_Q_wiVybUJxnSaQC4sdGR6pV0EseE-uBab-oQO5S419dTN-npB50b8dCMmxKDeN509UKRKuCFka4xUdKAyE9KxTyZRFtjPK_ikLusZnk0ukYs6lCZvabnuGxh6hrmT6Tv0x"
}
 */

const ServicioExterno = {
  reportarPago(payload) {
    return http.post('/ClientesExternos/ReportarPago', payload);
  },
  crearFomulario(payload) {
    return http.post('/ClientesExternos/RegistroWifi', payload);
  }
}

export default ServicioExterno;
