import axios from "axios"

let baseURL = process.env.REACT_APP_API_URL;

if (window.location.host.indexOf("localhost") > -1) {
    baseURL = process.env.REACT_APP_API_URL_DEV
}

const axiosInstance = axios.create({
    baseURL: baseURL
});

axiosInstance.interceptors.request.use((config) => {
    config.headers = {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Authorization",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
    };
    config.headers.Authorization = `Bearer ${sessionStorage.getItem("token")}`;

    return config;
}, (error) => {
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response) {
        if (error.response.status === 401) {
            window.location = "/login";
        }
        return Promise.reject({ error: error.response.data });
    }
    else {
        return Promise.reject({ error: error });
    }
});

export default axiosInstance;