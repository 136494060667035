import http from '../axios'

const Servicios = {
    obtener() {
        return http.get(`/Servicios/Activos`);
    },
    obtenerPorClientes(clienteId) {
        return http.get(`/Servicios/PorClientes?clienteId=${clienteId}`);
    },
    registrarServicio(payload) {
        return http.post('/Servicios/Nuevo', payload);
    },
    asignarServicios(payload) {
        return http.post('/Servicios/GuardarClienteServicio', payload);
    },
    eliminarServicio(clienteId) {
        return http.delete(`/Servicios/Eliminar?IdServicio=${clienteId}`);
    }
}

export default Servicios
